import AuthService from '@/services/auth';

export default {
    login({ commit }, user) {    
        commit('loginSuccess',user);
    },
    loginFailed({commit} , error){
        commit('loginFailure', error);
    },
    logout({ commit }) {
        commit('logout');
    },
    register({ commit }, user) {
        commit('registerRequest', user);
    
        // AuthService.register(user)
        // .then(
        //     response => {
        //         commit('registerSuccess', user);
        //         return Promise.resolve(response.data);
        //     },
        //     error => {
        //         commit('registerFailure', error);
        //         return Promise.reject(error);
        //     }
        // );
    }
};