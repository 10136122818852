export default {

    addPosts({ commit }, payload) {
        const { postData, postSearch } = payload;
        commit('PUSH_DATA_POST_LOADER', { posts: postData });
    },

    addTags({ commit }, payload) {
        const { tagData, type } = payload;
        commit('PUSH_TAG_DATA', { tags: tagData, type });
    },

    addDiscussions({ commit }, payload) {
        const { discusionData , vnfd_id } = payload;
        commit('PUSH_DATA_DISCUSSIONS', { data: discusionData, vnfd_id: vnfd_id });
    },

    like({ commit }, payload) {
        if (payload.action_type == 'like') {
            switch (payload.likeable_type) {
                case "vnfd":
                    commit('INCREMENT_LIKE_AND_LIKED_COUNT_POST', payload);
                    break;
                case "comment":
                    commit('INCREMENT_LIKE_AND_LIKED_COUNT_COMMENT', payload);
                    break;
                case "reply":
                    commit('INCREMENT_LIKE_AND_LIKED_COUNT_REPLIE', payload);
                    break;
                default:
                    break;
            }
        } else {
            switch (payload.likeable_type) {
                case "vnfd":
                    commit('DECREMENT_LIKE_AND_LIKED_COUNT_POST', payload);
                    break;
                case "comment":
                    commit('DECREMENT_LIKE_AND_LIKED_COUNT_COMMENT', payload);
                    break;
                case "reply":
                    commit('DECREMENT_LIKE_AND_LIKED_COUNT_REPLIE', payload);
                    break;
                default:
                    break;
            }
        }
    }
}