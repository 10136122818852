<template>
  <MBLayout v-if="layout == 4"></MBLayout>
  <PCLayout v-else></PCLayout>
  <LoginModal
    v-model="isOpenModalLogin"
    ref="loginModal"
    v-if="!loggedIn && isRenderLoginModal"
  />
</template>

<script>
import MBLayout from "@/layouts/mb/Layout";
import PCLayout from "@/layouts/pc/Layout";
import LoginModal from "@/components/modals/Login";
import AuthService from "@/services/auth";
import cartService from "@/services/cart";

import { useStore } from "vuex";
import { provide, ref } from "vue";
import { computed, watch } from "@vue/runtime-core";
import { authRoute } from "@/router/authGuard";
import { useRoute, useRouter } from "vue-router";
import { _dsEmptyObject } from "@/utlis/helpers";
import { useI18n } from "vue-i18n";

export default {
  components: {
    MBLayout,
    PCLayout,
    LoginModal,
  },
  name: "MainLayout",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const timestamp = new Date();
    const month = timestamp.getMonth() + 1;
    const year = timestamp.getFullYear();
    const isOpenModalLogin = ref(false);
    const isRenderLoginModal = ref(!authRoute.includes(route.name));
    const user = localStorage.getItem("user");
    const currentUser = computed(() => store.getters["account/userInfo"]);
    const currentMatchFilter = ref(0);
    getInitCart();

    if (user) {
      var userInfo = JSON.parse(user);
      var tokenExpiry = userInfo.expiry * 1000;
      var currentTime = new Date().getTime();

      if (currentTime > tokenExpiry) {
        logOut();
      }
    }

    // remove state when back to login page
    window.onpopstate = (event) => {
      if (user && route.name == "/login") {
        window.localtion.href = "/";
      }
    };

    watch(
      () => route.name,
      (newName) => {
        isRenderLoginModal.value = !authRoute.includes(newName);
      }
    );

    provide("closeModalLogin", () => (isOpenModalLogin.value = false));
    provide("openModalLogin", () => (isOpenModalLogin.value = true));
    provide("toggleModalLogin", () => (isOpenModalLogin.value = !isOpenModalLogin.value));

    function logOut() {
      AuthService.logout();
      store.dispatch("account/logout");
      router.push({ name: "login" });
    }

    provide("logOut", logOut);
    provide("currentMatchFilter", currentMatchFilter);
    provide("currentTime", timestamp);
    provide("currentMonth", month);
    provide("currentYear", year);
    provide("currentUser", currentUser);
    provide("t", t);

    async function getInitCart() {
      const response = await cartService.fetchCarts();

      if (response.data.cart != null) {
        store.dispatch("cart/initCart", response.data.cart);
      }
    }

    return {
      loggedIn: computed(() => store.state.account.status.loggedIn),
      isOpenModalLogin,
      isRenderLoginModal,
    };
  },
};
</script>
