<template>
    <ais-index :index-name="algoliaIndex">
        <ais-stats>
            <template v-slot="{ nbHits }">
                <span class="text-pink-gradient"> ({{showLimitStat(nbHits)}}) </span>
            </template>
        </ais-stats>
    </ais-index>
</template>

<script>
    export default {
        props:{
            algoliaIndex :{
                require : true,
                type    : String
            }
        },
        methods:{
            
        }
    }
</script>