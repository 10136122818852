import Api from '@/services/Api';

export default {
    fetchCarts(token) {
        return Api().get('/4.0/carts');
    },

    addProductToCart(productData){
        return Api().put(`/4.0/carts/${productData.product_id}` , { product_data : productData , mode : 'add' } );
    },

    addUserDataToCart(userData){
        return Api().put(`/4.0/carts/1` , {
            user_data : userData,
            mode : 'update_user_data' 
        });
    }
}