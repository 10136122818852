// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShareButtonDropdown_top_1i5Y8{top:0}.ShareButtonDropdown_left_BkuJ2{left:0}.ShareButtonDropdown_bgWhite_2taNn{background-color:#fff;border-radius:10px}", ""]);
// Exports
exports.locals = {
	"top": "ShareButtonDropdown_top_1i5Y8",
	"left": "ShareButtonDropdown_left_BkuJ2",
	"bgWhite": "ShareButtonDropdown_bgWhite_2taNn"
};
module.exports = exports;
