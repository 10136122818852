export default {
  PUSH_DATA_POST_LOADER(state, payload) {
    let posts = payload.posts
    if (posts.length) {
      posts.forEach(element => {
        state.postLoader[`post_${element.vnfd_id}`] = element
      });
    }
  },
  DELETE_POST_LOADER(state, payload) {
    state.postLoader = {};
  },
  PUSH_NEW_FEEDS(state, payload) {
    let posts = payload.posts
    if (posts.length) {
      posts.forEach(element => {
        state.newFeeds[`post_${element.vnfd_id}`] = element
      });
    }
  },
  PUSH_TAG_DATA(state, payload) {
    const { tags, type } = payload
    if (tags.length) {
      if (type == 'popular') {
        tags.forEach(element => {
          state.popularTags[`tags_${element.tag_id}`] = element
        });
      } else {
        tags.forEach(element => {
          state.trendingTags[`tags_${element.tag_id}`] = element
        });
      }
    }
  },
  PUSH_TAG_DATA_SEARCH(state, payload) {
    let search = payload.search;
    let type = payload.type;
    if (type == 'popular') {
      state.popularTagsSearch = search;
    } else {
      state.trendingTagsSearch = search;
    }
  },
  //discussion
  PUSH_DATA_DISCUSSIONS(state, payload) {
    state.discussions[`post_` + payload.vnfd_id] = payload.data;
  },
  PUSH_DISCUSSION_TO_DISCUSSIONS(state, payload) {
    let discussions = state.discussions[`post_` + payload.post_id];
    discussions['posts'].push(payload);
    state.discussions[`post_` + payload.post_id] = discussions;
    state.postLoader['post_' + payload.post_id].discussion_count = parseInt(state.postLoader['post_' + payload.post_id].discussion_count) + 1;
  },
  
  CHANGE_DISCUSSION_SORT(state, payload) {

    let { sort_by, sort_order, vnfd_id } = payload;
    let { discussions, params } = state.discussions[`post_` + payload.vnfd_id];

    params.sort_by = sort_by;
    params.sort_order = sort_order;

    discussions.sort(function(a, b) {
      if (parseInt(a.timestamp) < parseInt(b.timestamp)) {
        return payload.sort_order === 'asc' ? 1 : -1;
      }

      if (parseInt(a.timestamp) > parseInt(b.timestamp)) {
        return payload.sort_order === 'asc' ? -1 : 1;
      }

      return 0;
    });

    // update params sort 
    state.discussions[`post_${vnfd_id}`] = { params: params, discussions: discussions };
  },
  INCREMENT_LIKE_AND_LIKED_COUNT_POST(state, payload) {
    state.postLoader[payload.indexPost].is_like = true;
    state.postLoader[payload.indexPost].like_count++;
  },
  DECREMENT_LIKE_AND_LIKED_COUNT_POST(state, payload) {
    state.postLoader[payload.indexPost].is_like = false;
    state.postLoader[payload.indexPost].like_count--;
  },
  INCREMENT_LIKE_AND_LIKED_COUNT_COMMENT(state, payload) {
    state.postLoader[payload.indexPost].comments[payload.indexComment].like_count++;
  },
  DECREMENT_LIKE_AND_LIKED_COUNT_COMMENT(state, payload) {
    state.postLoader[payload.indexPost].comments[payload.indexComment].like_count--;
  },
  INCREMENT_LIKE_AND_LIKED_COUNT_REPLIE(state, payload) {
    state.postLoader[payload.indexPost].comments[payload.indexComment].replies[payload.indexReply].like_count++;
  },
  DECREMENT_LIKE_AND_LIKED_COUNT_REPLIE(state, payload) {
    state.postLoader[payload.indexPost].comments[payload.indexComment].replies[payload.indexReply].like_count--;
  },
}