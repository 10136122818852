import Api from '@/services/Api';

export default {
    fetchEvent(eventId){
        return Api().get(`/4.0/events/${eventId}`);
    },

    fetchEvents(queryString){
        return Api().get(`/4.0/events${queryString}`);
    },

    fetchExclusive(){
        return Api().get(`/4.0/exclusive`);
    },

    fetchOriginEvent(){
        return Api().get('/4.0/events?originEvent=true');
    }
}