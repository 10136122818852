<template>
  <Teleport to="#modals">
    <div class="dosiin_modal-login" v-if="isOpen">
      <div
        class="modal modal-full-screen dosiin_modal-login"
        :class="isOpen ? 'open' : ''"
        id="dosiin_modal-login"
      >
        <div class="bs-modal-login-overlay"></div>

        <div
          class="header-modal d-flex align-items-center justify-content-between"
          :class="{ 'dosiin_pd-16': layout == 4 }"
          v-if="layout == 4"
        >
          <div></div>
          <button
            class="dosiin_btn-close-modal back dosiin_mb-16"
            type="button"
            @click="$emit('update:modelValue', false)"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.30612 19.7668L12 13.0729L18.6939 19.7668C18.7638 19.8367 18.8571 19.9067 18.9504 19.93C19.0437 19.9767 19.137 20 19.2303 20C19.3236 20 19.4402 19.9767 19.5335 19.9534C19.6268 19.9067 19.7201 19.8601 19.7901 19.7901C19.8601 19.7201 19.9067 19.6268 19.9534 19.5335C20 19.4402 20 19.3469 20 19.2303C20 19.137 19.9767 19.0204 19.93 18.9504C19.8834 18.8571 19.8367 18.7872 19.7668 18.7172L13.0729 12L19.7668 5.30612C19.8834 5.1895 19.9534 5.07289 19.9767 4.90962C20 4.76968 20 4.60641 19.93 4.46647C19.8601 4.32653 19.7668 4.20991 19.6501 4.11662C19.5335 4.04665 19.3703 4 19.2303 4C19.0204 4 18.8338 4.09329 18.6939 4.23324L12 10.9271L5.30612 4.23324C5.23615 4.16327 5.14286 4.09329 5.04956 4.06997C4.95627 4.02332 4.86297 4 4.74636 4C4.60641 4 4.46647 4.04665 4.32653 4.13994C4.20991 4.20991 4.11662 4.32653 4.06997 4.46647C4.02332 4.60641 4 4.76968 4.02332 4.90962C4.04665 5.04956 4.11662 5.1895 4.23324 5.30612L10.9271 12L4.23324 18.6939C4.16327 18.7638 4.09329 18.8571 4.06997 18.9504C4.02332 19.0437 4 19.137 4 19.2303C4 19.3236 4.02332 19.4402 4.04665 19.5335C4.06997 19.6268 4.13994 19.7201 4.20991 19.7901C4.27988 19.8601 4.37318 19.9067 4.46647 19.9534C4.55977 20 4.65306 20 4.76968 20C4.86297 20 4.97959 19.9767 5.04956 19.93C5.14286 19.8834 5.23615 19.8367 5.30612 19.7668Z"
                fill="#E6E6E6"
              ></path>
            </svg>
          </button>
        </div>

        <div
          v-else
          class="back-header-overlay dosiin_back-header-sticky back-header-modal-login"
        >
          <div class="go-back-icon">
            <button
              class="brand_nav-back-btn btn-circle brand-nav-btn"
              type="button"
              @click="$emit('update:modelValue', false)"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.30612 19.7668L12 13.0729L18.6939 19.7668C18.7638 19.8367 18.8571 19.9067 18.9504 19.93C19.0437 19.9767 19.137 20 19.2303 20C19.3236 20 19.4402 19.9767 19.5335 19.9534C19.6268 19.9067 19.7201 19.8601 19.7901 19.7901C19.8601 19.7201 19.9067 19.6268 19.9534 19.5335C20 19.4402 20 19.3469 20 19.2303C20 19.137 19.9767 19.0204 19.93 18.9504C19.8834 18.8571 19.8367 18.7872 19.7668 18.7172L13.0729 12L19.7668 5.30612C19.8834 5.1895 19.9534 5.07289 19.9767 4.90962C20 4.76968 20 4.60641 19.93 4.46647C19.8601 4.32653 19.7668 4.20991 19.6501 4.11662C19.5335 4.04665 19.3703 4 19.2303 4C19.0204 4 18.8338 4.09329 18.6939 4.23324L12 10.9271L5.30612 4.23324C5.23615 4.16327 5.14286 4.09329 5.04956 4.06997C4.95627 4.02332 4.86297 4 4.74636 4C4.60641 4 4.46647 4.04665 4.32653 4.13994C4.20991 4.20991 4.11662 4.32653 4.06997 4.46647C4.02332 4.60641 4 4.76968 4.02332 4.90962C4.04665 5.04956 4.11662 5.1895 4.23324 5.30612L10.9271 12L4.23324 18.6939C4.16327 18.7638 4.09329 18.8571 4.06997 18.9504C4.02332 19.0437 4 19.137 4 19.2303C4 19.3236 4.02332 19.4402 4.04665 19.5335C4.06997 19.6268 4.13994 19.7201 4.20991 19.7901C4.27988 19.8601 4.37318 19.9067 4.46647 19.9534C4.55977 20 4.65306 20 4.76968 20C4.86297 20 4.97959 19.9767 5.04956 19.93C5.14286 19.8834 5.23615 19.8367 5.30612 19.7668Z"
                  fill="#E6E6E6"
                />
              </svg>
            </button>
          </div>
          <img :src="_logoWhite" width="86" height="27" alt="Dosi-in" />
        </div>

        <div class="social-group">
          <div class="intro" v-if="layout == 4">
            <p>Chào mừng bạn đến với Dosiin</p>
          </div>

          <div class="box-wrapper">
            <div class="intro" v-if="layout != 4">
              <p>Đăng nhập</p>
            </div>

            <LoginForm />

            <div class="register-link center dosiin_text-center">
              Bạn chưa có tài khoản?
              <router-link :to="{ name: 'register' }" class="primary-link_text">
                Đăng ký
              </router-link>
            </div>
            <div class="condition">
              Bằng cách tiếp tục bạn đã Đồng ý với <br />
              <a href="/dieu-khoan-dich-vu">Điều khoản dịch vụ</a>,
              <a href="/chinh-sach-bao-mat">Chính sách bảo mật Dosiin</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm";
import { computed } from "vue";

export default {
  props: {
    modelValue: Boolean,
  },
  components: {
    LoginForm,
  },
  emits: ["update:modelValue"],
  setup(props) {
    const isOpen = computed({
      get: () => props.modelValue,
      set: () => emit("update:modelValue", value),
    });

    return {
      isOpen,
    };
  },
};
</script>
