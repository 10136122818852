import algoliasearch from 'algoliasearch/lite';
const PHPUnserialize = require('@/libs/phpUnserialize');
import moment from 'moment';
import store from '@/store';

import {
  _postImage,
  _postThumbnail,
  _productThumbnail,
  _productThumbnailSticker,
  _productThumbnailSerializie,
  _postImageWithFullUrl,
  _companyAvatar,
  _companyAvatarNewest,
  _userAvatar,
  _userRandomAvatar,
  _catetagThumbnail,
  _companyBanner,
  _brandBanner,
  _brandBannerCrop,
  _bannerImage,
  _promotionThumb,
  _commentThumb
} from '@/utlis/thumbnail';

import {
  _siteUrl,
  _categoryUrl,
  _productUrl,
  _brandUrl,
  _vnfdUrl,
  _addLeadingZero
} from '@/utlis/helpers';

import { _getCookie } from '@/utlis/cookie';
// directives
import passwordDirective from '@/directives/password';
import dropdownDirective from '@/directives/dropdown';
// config
import { category_icons } from '@/utlis/global';
import { _setCookie } from './utlis/cookie';

moment.locale('vi');

export default {
  directives: {
    password: passwordDirective,
    dropdown: dropdownDirective
  },
  data() {
    return {
      searchClientKeys: algoliasearch(
        process.env.VUE_APP_ALGOLIA_KEY,
        process.env.VUE_APP_ALGOLIA_SECRET_KEY, {
          headers: {
            'X-Algolia-UserToken': this._dosiinLogged() ? this._userDosiinInfo().user_id : _getCookie('dsi'),
          }
        }
      ),
      layout: Tygh.layout,
      _defaultImage: 'https://dosi-in.com/images/assets/default-dosiin-product.png',
      _styleDefault: 'https://dosi-in.com/images/assets/default-dosiin-style.png',
      _bannerDefault: 'https://dosi-in.com/images/assets/default-dosiin-banner.png',
      _userDefaultAvatar: 'https://dosi-in.com/file/user_avatar/301/dosiin-default-7.jpg?w=320&h=320&fm=webp',
      _userNoAvatar: 'https://dosi-in.com/images/v4/no_avatar.png',
      _polygon: 'https://dosi-in.com/images/v4/icons/polygon.png',
      _event: 'https://dosi-in.com/images/assets/icons/notification-event.png',
      _promotion: 'https://dosi-in.com/images/assets/icons/notification-promotion.png',
      _brand: 'https://dosi-in.com/images/assets/icons/notification-brands.png',
      _logo: 'https://dosi-in.com/images/assets/icons/logo.svg',
      _logoWhite: 'https://dosi-in.com/images/assets/icons/logo-white.png',
      _emptyData: 'https://dosi-in.com/images/assets/icons/no-results.png',
      _emptyDescription: 'https://dosi-in.com/images/assets/icons/no-description.png',
      _emptyReview: 'https://dosi-in.com/images/assets/icons/no-review.png',
      _topicIcon: 'https://dosi-in.com/images/v4/tag.png',
      _closeCoupon: 'https://dosi-in.com/images/assets/icons/close-coupon.png',
      _giftCoupon: 'https://dosi-in.com/images/assets/icons/gif-box-coupon.png',
      _closeButton: 'https://dosi-in.com/images/assets/icons/close-button.png'
    }
  },
  methods: {
    _dosiinLogged() {
      return store.state.account.status.loggedIn
    },
    _userDosiinInfo() {
      if (this._dosiinLogged()) {
        return store.state.account.user;
      }
      return {};
    },
    currencyFormat(price, country = 'vi') {
      return parseInt(price).toLocaleString(country, { style: 'currency', currency: 'VND' });
    },
    ratingFormat(rating) {
      if (!rating) return '0.0';
      else return parseFloat(rating).toFixed(1);
    },
    numberFormat(number, decimals, dec_point, thousands_sep) {
      // Strip all characters but numerical ones.
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return '' + Math.round(n * k) / k;
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    },
    showLimitStat(nbHits) {

      if (nbHits > 99) {
        return 99 + '+';
      }
      return this.numberFormat(nbHits);
    },
    unserialize(str) {
      return PHPUnserialize.unserialize(str);
    },
    serialize(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return '?' + str.join("&");
    },
    _momentFromNow(unix) {
      if (typeof unix == 'undefined') {
        return '__/__/__';
      }

      let strDate = moment(unix * 1000).startOf('seconds').fromNow();

      strDate = strDate.replace('tới', '');
      strDate = strDate.replace('trước', '');

      return strDate.replace("một", "1");
    },
    _momentDateMonth(unix) {
      return moment.unix(unix).format("DD/MM");
    },
    _momentDateMonthYear(unix) {
      return moment.unix(unix).format("DD/MM/YYYY");
    },
    _momentDayToDateString(unix) {
      return moment.unix(unix).format('LLLL');
    },
    goBack() {
      this.$router.back();
    },
    discountPrc(product) {
      return Math.ceil(((product.list_price - product.price) / product.list_price) * 100);
    },
    dosiinEmptyObject(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }
      return true;
    },
    getCategoryIcon(category_id) {
      category_id = parseInt(category_id);
      return category_icons[category_id];
    },
    scrollToTop() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    },
    _siteUrl() {
      return _siteUrl();
    },
    _categoryUrl(category) {
      return _categoryUrl(category);
    },
    _productUrl(product) {
      return _productUrl(product);
    },
    _brandUrl(seo_name) {
      return _brandUrl(seo_name);
    },
    _vnfdUrl(seo_name) {
      return _vnfdUrl(seo_name);
    },
    _addLeadingZero(number) {
      return _addLeadingZero(number);
    },
    _postImage(pairsObject) {
      return _postImage(pairsObject);
    },
    _postThumbnail(pairsObject) {
      return _postThumbnail(pairsObject);
    },
    _productThumbnailSticker(pairsObject, stickerObject, origin) {
      return _productThumbnailSticker(pairsObject, stickerObject, origin);
    },
    _productThumbnailSerializie(pairsObjectString) {
      return _productThumbnailSerializie(pairsObjectString);
    },
    _eventThumbnail(pairsObject) {
      return _postThumbnail(pairsObject);
    },
    _productThumbnail(pairsObject, origin) {
      return _productThumbnail(pairsObject, origin);
    },
    _companyAvatar(pairsObject) {
      return _companyAvatar(pairsObject);
    },
    _companyAvatarNewest(pairsObject) {
      return _companyAvatarNewest(pairsObject);
    },
    _userAvatar(pairsObject) {
      return _userAvatar(pairsObject);
    },
    _userRandomAvatar() {
      return _userRandomAvatar();
    },
    _postImageWithFullUrl(pairsObject) {
      return _postImageWithFullUrl(pairsObject);
    },
    _catetagThumbnail(pairsObject) {
      return _catetagThumbnail(pairsObject);
    },
    _companyBanner(pairsObject) {
      return _companyBanner(pairsObject);
    },
    _brandBanner(pairsObject) {
      return _brandBanner(pairsObject);
    },
    _brandBannerCrop(pairsObject) {
      return _brandBannerCrop(pairsObject);
    },
    _bannerImage(pairsObject) {
      return _bannerImage(pairsObject);
    },
    _promotionThumb(pairsObject) {
      return _promotionThumb(pairsObject);
    },
    _commentThumb(src, thumb = true) {
      return _commentThumb(src, thumb);
    }
  },
}