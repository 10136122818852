const PHPUnserialize = require('@/libs/phpUnserialize');
import { _dsEmptyObject } from '@/utlis/helpers';

export function serialize(obj){
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p) && ![null,'',[] , undefined,NaN].includes(obj[p])) {
            if(typeof obj[p] == 'object'){
                if(_dsEmptyObject(obj[p])) continue;
            }

            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }else{
            // console.log(`value : ${obj[p]}`)
        }
    return '?' + str.join("&");
}


export function unserialize(str){
    return PHPUnserialize.unserialize(str);
}