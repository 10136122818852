<template>
  <header>
    <div class="page-header" id="dosiin_page-header">
      <div class="header-inner dosiin_header-inner">
        <ul class="category-list">
          <template v-for="(menu, i) in RootMenu" :key="i">
            <template v-if="i == 0">
              <li
                v-if="
                  (Object.keys(route.query).length == 0 &&
                    (route.name == 'categories' || route.name == 'shops')) ||
                  (menu.type == 'shopping' && route.meta.isShopping)
                "
                class="category-item dosiin_category-item active"
              >
                <router-link :to="menu.route" :title="menu.des">
                  {{ menu.title }}
                </router-link>
              </li>
              <li v-else class="category-item dosiin_category-item inactive">
                <router-link :to="menu.route" :title="menu.des">
                  {{ menu.title }}
                </router-link>
              </li>
            </template>
            <template v-else>
              <li
                :class="
                  ((route.name == 'categories' || route.name == 'shops') &&
                    route.meta.isShopping &&
                    route.query.type_category == menu.type) ||
                  route.name == menu.type
                    ? 'active'
                    : 'inactive'
                "
                class="category-item dosiin_category-item"
              >
                <router-link :to="menu.route" :title="menu.des">
                  {{ menu.title }}
                </router-link>
              </li>
            </template>
            <li class="category-item-divider" v-if="i !== RootMenu.length - 1"></li>
          </template>
        </ul>

        <div class="group-search-cart">
          <router-link to="/search" class="search-icon dosiin_search-header-btn">
            <i class="dsi dsi-search"></i>
          </router-link>
          <a href="/cart" class="cart-icon dosiin_cart-header-btn">
            <i class="dsi dsi-cart"></i>
            <span class="item-badge" v-if="cartAmount > 0">{{ cartAmount }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="sticky-header" id="dosiin_header-sticky" ref="stickyHeader">
      <div class="header-left">
        <div
          class="header-left_button"
          id="dosiin_choose-classify-modal-btn"
          ref="chooseClassifyBtn"
          @click="toggleModal"
        >
          <div class="text" ref="chooseClassifyBtnContent">
            {{ menuText }}
          </div>
          <div class="dropup-arrow">
            <i class="dsi dsi-s12 dsi-chevron-down dropup-arrow-down"></i>
            <i class="dsi dsi-s12 dsi-act-chevron-up dropup-arrow-up"></i>
          </div>
        </div>
      </div>
      <a href="/">
        <img :src="_logo" width="86" height="27" alt="Dosi-in" />
      </a>
      <div class="header-right">
        <div class="group-search-cart">
          <a class="search-icon dosiin_search-header-btn" href="/search">
            <i class="dsi dsi-search"></i>
          </a>
          <a class="cart-icon dosiin_cart-header-btn" href="/cart">
            <i class="dsi dsi-cart"></i>
            <span class="item-badge" v-if="cartAmount > 0">{{ cartAmount }}</span>
          </a>
        </div>
      </div>
    </div>

    <div
      class="choose-classify-modal-overlay modal"
      id="dosiin_choose-classify-modal"
      ref="chooseClassifyModal"
    >
      <div class="background"></div>
      <div class="choose-classify-modal-container" ref="chooseClassifyContainer">
        <div class="choose-classify-modal-header">
          <div ref="cancelBtn" class="choose-classify-modal_cancel" @click="toggleModal">
            <i class="dsi dsi-close"></i>
          </div>
          <div class="choose-classify-modal_title">Phân loại</div>
        </div>
        <div class="choose-classify-modal-content">
          <a
            v-for="(menu, i) in RootMenu"
            :key="i"
            class="choose-classify-modal_class dosiin_choose-classify-modal_class"
            :class="{ checked: route.fullPath == menu.route }"
            :href="menu.route"
          >
            <span class="choose-classify-modal_class_right_icon">
              <i :class="menu.iconClass"></i>
            </span>
            <span class="choose-classify-modal_class_content">
              <span class="choose-classify-modal_class_title">{{ menu.title }}</span>
              <span class="choose-classify-modal_class_desc">{{ menu.des }}</span>
            </span>
            <span
              class="dosiin_choose-classify-modal_class_left_icon choose-classify-modal_class_left_icon"
            >
              <span class="check-box"></span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { RootMenu1, RootMenu2 } from "@/utlis/global";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const products = computed(() => store.getters["cart/cartProducts"]);
    const total = computed(() => store.getters["cart/cartTotalPrice"]);
    const currentUser = computed(() => store.getters["account/userInfo"]);
    const userToken = computed(() => store.getters["account/getUserToken"]);
    const cartAmount = computed(() => store.getters["cart/cartTotalItems"]);
    const chooseClassifyBtn = ref(null);
    const chooseClassifyModal = ref(null);
    const stickyHeader = ref(null);
    const RootMenu = ref([]);

    if (route.name == "shops") {
      RootMenu.value = RootMenu2;
    } else {
      RootMenu.value = RootMenu1;
    }

    watch(
      () => route.name,
      () => {
        if (route.name == "shops") {
          RootMenu.value = RootMenu2;
        } else {
          RootMenu.value = RootMenu1;
        }
      }
    );

    const menuText = computed(() => {
      let activeMenu = RootMenu.value.find((item) => item.route == route.fullPath);

      if (activeMenu) {
        return activeMenu.title;
      }

      return "Tất cả";
    });

    const toggleModal = () => {
      chooseClassifyBtn.value.classList.toggle("modal-opened");
      chooseClassifyModal.value.classList.toggle("open");
      stickyHeader.value.classList.toggle("mode-show-modal");
      document.body.classList.toggle("no-scroll");
    };

    onMounted(() => {
      const elementHeader = document.querySelector("#dosiin_page-header");
      const stickyElement = document.querySelector("#dosiin_header-sticky");
      if (elementHeader && stickyElement) {
        const observer = new IntersectionObserver(function (entries) {
          if (!entries[0].isIntersecting) {
            stickyElement.classList.add("mode-sticky");
          } else {
            stickyElement.classList.remove("mode-sticky");
          }
        });

        observer.observe(elementHeader);
      }
    });

    return {
      route,
      products,
      total,
      currentUser,
      userToken,
      cartAmount,
      RootMenu,
      menuText,
      // refs
      chooseClassifyBtn,
      chooseClassifyModal,
      stickyHeader,
      //methods
      toggleModal,
    };
  },
};
</script>
