export const category_icons = {
  2: 'dsi-shoes-male',
  4: 'dsi-hat',
  5: 'dsi-pants',
  12: 'dsi-accessory-male',
  23: 'dsi-shirt',
  25: 'dsi-sweater',
  26: 'dsi-hoodie',
  27: 'dsi-coat',
  29: 'dsi-turshirt',
  31: 'dsi-jacket',
  32: 'dsi-handbag',
  46: 'dsi-skirt',
  91: 'dsi-croptop',
  95: 'dsi-bra',
  98: 'dsi-jumpsuit',
  99: 'dsi-dress',
  102: 'dsi-shirt',
  106: 'dsi-tanktop',
  113: 'dsi-swimsuit',
  114: 'dsi-shoes-female',
  115: 'dsi-accessory-male',
  116: 'dsi-accessory-female',
  133: 'dsi-sleepwear',
  137: 'dsi-perfume',
  104: 'dsi-shirt',
  35: 'dsi-accessory-male',
  63: 'dsi-jacket',
  105: 'dsi-shirt',
  93: 'dsi-shirt',
  65: 'dsi-shirt',
  117: 'dsi-shirt',

}

export const category_name_itm = {
  2: 'sneaker',
  4: 'hat',
  5: 'bottoms',
  12: 'accessories',
  25: 'sweater',
  26: 'hoodie',
  27: 'shirt',
  29: 'polotop',
  31: 'jacket',
  32: 'bag',
  46: 'skirt',
  91: 'croptop',
  95: 'undiespyjama',
  98: 'jumpsuit',
  99: 'dress',
  102: 'tee',
  106: 'tanktop',
  113: 'swimwear',
  114: 'femaleshoes',
  115: 'maleacc',
  116: 'femaleaccc',
  133: 'wholeset',
  137: 'perfume',
}

export const defaultAvatar = [
  'https://dosi-in.com/images/v4/avatar/avatar-1.png',
  'https://dosi-in.com/images/v4/avatar/avatar-2.png',
  'https://dosi-in.com/images/v4/avatar/avatar-3.png',
  'https://dosi-in.com/images/v4/avatar/avatar-4.png',
  'https://dosi-in.com/images/v4/avatar/avatar-5.png',
  'https://dosi-in.com/images/v4/avatar/avatar-6.png',
  'https://dosi-in.com/images/v4/avatar/avatar-7.png',
  'https://dosi-in.com/images/v4/avatar/avatar-8.png',
];

export const productGridClass = [
  'grid-11',
  'grid-50',
  'grid-33'
];

export const productPerPage = 30;

export const RootMenu1 = [
  { title: 'Cửa hàng', route: '/', type: 'shopping', des: 'Mua sắm sản phẩm từ các thương hiệu nổi tiếng', iconClass: 'dsi dsi-s24 dsi-fill-storedosiin' },
  // { title : 'Tin tức' , route : '/magazine' ,  type : 'news', des : 'Cập nhật tin tức nóng bỏng về thời trang' , iconClass : 'dsi dsi-s24 dsi-news'},
  { title: 'Styles', route: '/style', type: 'style', des: 'Thời Trang Local Brand của người trẻ Việt', iconClass: 'dsi dsi-s24 dsi-coat' }
];

export const RootMenu2 = [
  { title: 'Tất cả', route: '/shops', des: 'Toàn bộ sản phẩm Nam, Nữ và Phụ kiện', iconClass: 'dsi dsi-s24 dsi-fill-genderall', type: '' },
  { title: 'Nam', route: '/shops?type_category=male', des: 'Toàn bộ sản phẩm dành cho Nam', iconClass: 'dsi dsi-s24 dsi-fill-gendermen', type: 'male' },
  { title: 'Nữ', route: '/shops?type_category=female', des: 'Toàn bộ sản phẩm dành cho Nữ', iconClass: 'dsi dsi-s24 dsi-fill-genderwomen', type: 'female' },
  { title: 'Phụ kiện', route: '/shops?type_category=other', des: 'Toàn bộ sản phẩm Phụ kiện', iconClass: 'dsi dsi-s24 dsi-fill-genderaccessory', type: 'other' }
];


export const ReviewText = {
  'T': 'Chật',
  'C': 'Thoải mái',
  'W': 'Rộng'
}

export const socialIcons = {
  "facebook": "dsi dsi-color-fbcircle",
  "instagram": "dsi dsi-color-instacircle",
  "zalo": " dsi dsi-color-zalocirclegreen",
  "website": "dsi dsi-language"
}