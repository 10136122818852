export function _siteUrl() {
  return window.location.origin;
}

export function _categoryUrl(category) {
  return `/${category.seo_name}`;
}

export function _productUrl(product) {
  return `/${product.seo_name}`;
}

export function _brandUrl(seo_name) {
  return `/${seo_name}`;
}

export function _vnfdUrl(seo_name) {
  return `/${seo_name}`;
}

export function _dsEmptyObject(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}

export function _addLeadingZero(number) {
  if (number < 10) {
    return `0${number}`;
  }

  return number;
}

export function _trimString(s) {
  var l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] == ' ') l++;
  while (r > l && s[r] == ' ') r -= 1;
  return s.substring(l, r + 1);
}

export function _stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function _compareObjects(o1, o2) {
  var k = '';
  for (k in o1)
    if (o1[k] != o2[k]) return false;
  for (k in o2)
    if (o1[k] != o2[k]) return false;
  return true;
}

export function _itemExists(haystack, needle) {
  for (var i = 0; i < haystack.length; i++)
    if (_compareObjects(haystack[i], needle)) return true;
  return false;
}

export function _groupByAlphabet(object, indexName = 'name') {
  let data = object.reduce((r, e) => {
      // get first letter of name of current element
      let group = e[indexName][0].toUpperCase();

      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(group)) group = '0-9';
      // if there is no property in accumulator with this letter create it
      if (!r[group]) r[group] = { group, items: [e] }
        // if there is push current element to children array for that letter
      else r[group].items.push(e);
      // return accumulator
      return r;
    }, {})
    // Object.values(data);
  return data;
}