<template>
  <div
    class="social-interact-overlay modal"
    :class="{ open: !dosiinEmptyObject(postShare) }"
    id="dosiin_social-interact-modal"
  >
    <div
      class="modal-container_bottom social-interact-modal_container"
      id="dosiin_social-modal_container"
    >
      <div class="social-interact_btn-group">
        <a class="btn-follow btn-social" target="_blank" :href="shareFb">
          Share on Facebook
        </a>

        <a class="btn-follow btn-social" @click="shareMess"> Share on Messenger </a>

        <a class="btn-like btn-social" target="_blank" :href="shareTwit">
          Share on Twitter
        </a>

        <a
          class="zalo-share-button btn-like btn-social"
          :data-href="shareZalo"
          data-oaid="579745863508352884"
          data-layout="2"
          data-color="white"
          data-customize="false"
          >Share on Zalo
        </a>

        <a class="btn-like btn-social" @click="copyLinkToClipboard">Copy link</a>

        <input type="hidden" :value="shareZalo" ref="postLinkToCopy" />
      </div>
      <button
        class="btn-cancel btn-social dosiin_warning-primary"
        @click="postShare = {}"
        type="button"
      >
        Hủy bỏ
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      postShare: {},
      originUrl: window.location.origin,
    };
  },

  computed: {
    shareFb() {
      return (
        "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(this.originUrl + "/" + this.postShare.seo_name)
      );
    },
    shareTwit() {
      return (
        "https://twitter.com/intent/tweet?text=" +
        encodeURIComponent(this.postShare.short_description) +
        "&url=" +
        encodeURIComponent(this.originUrl + "/" + this.postShare.seo_name)
      );
    },
    shareZalo() {
      return window.location.origin + "/" + encodeURIComponent(this.postShare.seo_name);
    },
  },
  updated() {
    ZaloSocialSDK.reload();
  },
  methods: {
    updatePost(post) {
      this.postShare = post;
    },
    copyLinkToClipboard() {
      var linkToShare = this.$refs.postLinkToCopy;
      linkToShare.setAttribute("type", "text");
      // linkToShare.innerHTML = window.location.href;
      linkToShare.select();
      document.execCommand("copy");
      linkToShare.setAttribute("type", "hidden");
      showNotify("N", "Link đã được sao chép");
    },
    shareMess() {
      let originLink = encodeURIComponent(this.originUrl + "/" + this.postShare.seo_name);

      if (this.layout == 4) {
        window.open("fb-messenger://share/?link=" + originLink + "&app_id=123456789");
      } else {
        window.open(
          "http://www.facebook.com/dialog/send?app_id=640120160152421&display=popup&link=" +
            originLink +
            "&redirect_uri=" +
            originLink,
          "targetWindow",
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=500`
        );
      }
    },
  },
};
</script>

<style>
.zalo-share-button {
  overflow: unset !important;
  display: unset !important;
  width: unset !important;
  height: unset !important;
  text-align: center;
}
</style>
