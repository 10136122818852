export default {
  getLikeCountPost: (state) => (id) => {
    if (state.postLoader && state.postLoader[id]) {
      return state.postLoader[id].like_count;
    } else {
      return 0;
    }
  },
  getPostLoader(state) {
    return state.postLoader;
  },
  getNewFeeds(state) {
    return state.newFeeds;
  },
  getPost: (state) => (id) => {
    return state.postLoader[id];
  },
  getDiscussion: (state) => (id) => {
    if(typeof state.discussions[`post_`+id] != 'undefined'){
      return state.discussions[`post_`+id];
    }

    return {
      discussions: {},
      search: {},
    };
  },
};
