export default {
    substr(str) {
        if (str.length > 10) {
            return str.substring(0, 10) + '...';
        }
        return str;
    },
    trim(string) {
        return string.trim();
    }
}