const state = {
  count: 0,
  modalOpenCount: 0,
};

const getters = {
  count: (state) => {
    return state.count;
  },
  countOpen: (state) => {
    return state.modalOpenCount;
  },
};

const mutations = {
  initModal(state) {
    state.count++;
  },
  increase(state) {
    state.modalOpenCount++;
    if (state.modalOpenCount === 1) {
      document.body.classList.add("no-scroll");
      if (Tygh.layout != 4 && window.location.pathname != "/shops") {
        document.body.classList.add("dosiin_overlay-body");
      }
    }
  },
  decrease(state) {
    if(state.modalOpenCount === 0) return;
    state.modalOpenCount--;
    if (state.modalOpenCount === 0) {
      document.body.classList.remove("no-scroll");
      document.body.classList.remove("dosiin_overlay-body");
    }
  },
};

const actions = {
  initModal({ commit }) {
    commit("initModal");
  },
  increase({ commit }) {
    commit("increase");
  },
  decrease({ commit }) {
    commit("decrease");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
