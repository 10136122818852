<template>
  <div class="dsi_header-inner">
    <a
      v-if="router.options.history.state.back && route.name != 'home-home'"
      href="/"
      @click.prevent="goBack"
      class="header-come-back"
    >
      <i class="dsi dsi-chevron-left"> </i>
      <span class="text-back">Quay lại</span>
    </a>

    <a v-else class="header-come-back"></a>

    <div class="dsi_header-center">
      <ul class="dsi_header-tab-list dosiin_d-flex m-menu">
        <li
          class="m-menu-sub"
          id="m-menu-sub"
          :class="{
            is_active:
              (route.name == 'categories' || route.name == 'shops') &&
              Object.keys(route.query).length == 0,
          }"
          @mouseenter="showCategory('all')"
          @mouseleave="hideCategory('all')"
        >
          <router-link :to="{ name: 'shops' }">
            <span class="m-menu-ttl">Sản phẩm</span>
          </router-link>
        </li>
        <li
          class="m-menu-sub"
          :class="{
            is_active:
              (route.name == 'categories' || route.name == 'shops') &&
              route.query.type_category == 'male',
          }"
          @mouseenter="showCategory('male')"
          @mouseleave="hideCategory('male')"
        >
          <router-link :to="{ name: 'shops', query: { type_category: 'male' } }">
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.male')"></span>
          </router-link>
        </li>
        <li
          class="m-menu-sub"
          :class="{
            is_active:
              (route.name == 'categories' || route.name == 'shops') &&
              route.query.type_category == 'female',
          }"
          @mouseenter="showCategory('female')"
          @mouseleave="hideCategory('female')"
        >
          <router-link :to="{ name: 'shops', query: { type_category: 'female' } }">
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.female')"></span>
          </router-link>
        </li>
        <li
          class="m-menu-sub"
          :class="{
            is_active:
              (route.name == 'categories' || route.name == 'shops') &&
              route.query.type_category == 'other',
          }"
          @mouseenter="showCategory('other')"
          @mouseleave="hideCategory('other')"
        >
          <router-link :to="{ name: 'shops', query: { type_category: 'other' } }">
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.accessory')"></span>
          </router-link>
        </li>
        <li
          class="m-menu-sub"
          :class="{
            is_active:
              route.name == 'brand' ||
              route.name == 'brands-bestselling' ||
              route.name == 'brands-highlight' ||
              route.name == 'brands-newarrival',
          }"
        >
          <router-link :to="{ name: 'brand' }">
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.brands')"></span>
          </router-link>
        </li>
        <li
          class="m-menu-sub"
          :class="{ is_active: ['ranking', 'best-selling'].includes(route.name) }"
        >
          <router-link :to="{ name: 'ranking' }" :title="$t('menus.shop_menu.ranking')">
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.ranking')"></span>
          </router-link>
        </li>
        <li
          class="m-menu-sub"
          :class="{ is_active: ['newest', 'new-arrival'].includes(route.name) }"
        >
          <router-link
            :to="{
              name: 'new-arrival',
              query: {
                order_by: 'timestamp',
                itm_source: 'homepage',
                itm_medium: 'menu',
                itm_campaign: 'normal',
                itm_content: 'newproduct',
              },
            }"
            title="Sản phẩm mới"
          >
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.newest')"></span>
          </router-link>
        </li>
        <li
          class="m-menu-sub"
          :class="{
            is_active:
              route.name == 'on-sale' ||
              route.name == 'products-shock' ||
              route.name == 'promotion-detail',
          }"
        >
          <router-link :to="{ name: 'on-sale' }" :title="$t('menus.shop_menu.onsale')">
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.onsale')"></span>
          </router-link>
        </li>
        <!-- <li
          class="m-menu-sub"
          :class="{
            is_active: route.name == 'musinsa',
          }"
        >
          <p class="firework-animation">
            <img
              class="animation"
              src="https://dosi-in.com/images/assets/animation/notificantion-dot.gif"
              width="12"
              height="12"
              alt="animation"
            />
          </p>
          <a
            href="https://musinsa.dosi-in.com/"
            target="_blank"
            :title="$t('menus.shop_menu.musinsa')"
          >
            <span
              class="m-menu-ttl"
              style="color: #fe252c"
              v-text="$t('menus.shop_menu.musinsa')"
            ></span>
          </a>
        </li> -->
        <!-- <li class="m-menu-sub" :class="{ is_active: route.name == 'events' }"> -->
        <!-- <p class="firework-animation"><img class="animation" src="https://dosi-in.com/images/assets/animation/notificantion-dot.gif" width="12" height="12" alt="animation"></p> -->
        <!-- off notification -->
        <!-- <router-link :to="{ name: 'events' }" :title="$t('menus.shop_menu.events')">
            <span class="m-menu-ttl" v-text="$t('menus.shop_menu.events')"></span>
          </router-link>
        </li> -->
      </ul>
    </div>

    <div class="dsi_header-right"></div>

    <Teleport to="#modals">
      <div class="" :class="{ open: state.showCategory, 'd-none': !state.showCategory }">
        <div class="category_aside-inner">
          <LoadingComponent v-if="state.loadingCat" />
          <ul
            v-else
            class="aside_bar-list"
            @mouseenter="showCategory('all')"
            @mouseleave="hideCategory('all')"
          >
            <li
              class="category_column"
              v-for="(categories, index) in state.menuCategoroies"
              :key="index"
            >
              <div v-for="(category, i) in categories" :key="i">
                <dl>
                  <dt>
                    <router-link
                      :to="_categoryUrl(category)"
                      :title="`${category.category}`"
                    >
                      <i :class="['dsi', getCategoryIcon(category.category_id)]"></i>
                      <span>{{ category.category }}</span>
                    </router-link>
                  </dt>
                  <div v-if="category.subcategories">
                    <dd v-for="(subCat, i) in category.subcategories" :key="i">
                      <router-link
                        class="dosiin_d-flex"
                        :to="_categoryUrl(subCat)"
                        :title="`${subCat.category}`"
                      >
                        <span>{{ subCat.category }}</span>
                      </router-link>
                    </dd>
                  </div>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Teleport>

    <Teleport to="#modals">
      <div
        class=""
        :class="{ open: state.showCategoryMale, 'd-none': !state.showCategoryMale }"
      >
        <div class="category_aside-inner">
          <LoadingComponent v-if="state.loadingCat" />
          <ul
            v-else
            class="aside_bar-list"
            @mouseenter="showCategory('male')"
            @mouseleave="hideCategory('male')"
          >
            <li
              class="category_column"
              v-for="(categories, index) in state.menuCategoroiesMale"
              :key="index"
            >
              <div v-for="(category, i) in categories" :key="i">
                <dl>
                  <dt>
                    <router-link
                      :to="{
                        path: _categoryUrl(category),
                        query: { type_category: 'male' },
                      }"
                      :title="`${category.category}`"
                    >
                      <i :class="['dsi', getCategoryIcon(category.category_id)]"></i>
                      <span>{{ category.category }}</span>
                    </router-link>
                  </dt>
                  <div v-if="category.subcategories">
                    <dd v-for="(subCat, i) in category.subcategories" :key="i">
                      <router-link
                        class="dosiin_d-flex"
                        :to="{
                          path: _categoryUrl(subCat),
                          query: { type_category: 'male' },
                        }"
                        :title="`${subCat.category}`"
                      >
                        <span>{{ subCat.category }}</span>
                      </router-link>
                    </dd>
                  </div>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Teleport>

    <Teleport to="#modals">
      <div
        class=""
        :class="{ open: state.showCategoryFemale, 'd-none': !state.showCategoryFemale }"
      >
        <div class="category_aside-inner">
          <LoadingComponent v-if="state.loadingCat" />
          <ul
            v-else
            class="aside_bar-list"
            @mouseenter="showCategory('female')"
            @mouseleave="hideCategory('female')"
          >
            <li
              class="category_column"
              v-for="(categories, index) in state.menuCategoroiesFemale"
              :key="index"
            >
              <div v-for="(category, i) in categories" :key="i">
                <dl>
                  <dt>
                    <router-link
                      :to="{
                        path: _categoryUrl(category),
                        query: { type_category: 'female' },
                      }"
                      :title="`${category.category}`"
                    >
                      <i :class="['dsi', getCategoryIcon(category.category_id)]"></i>
                      <span>{{ category.category }}</span>
                    </router-link>
                  </dt>
                  <div v-if="category.subcategories">
                    <dd v-for="(subCat, i) in category.subcategories" :key="i">
                      <router-link
                        class="dosiin_d-flex"
                        :to="{
                          path: _categoryUrl(subCat),
                          query: { type_category: 'female' },
                        }"
                        :title="`${subCat.category}`"
                      >
                        <span>{{ subCat.category }}</span>
                      </router-link>
                    </dd>
                  </div>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Teleport>

    <Teleport to="#modals">
      <div
        class=""
        :class="{ open: state.showCategoryOther, 'd-none': !state.showCategoryOther }"
      >
        <div class="category_aside-inner">
          <LoadingComponent v-if="state.loadingCat" />
          <ul
            v-else
            class="aside_bar-list"
            @mouseenter="showCategory('other')"
            @mouseleave="hideCategory('other')"
          >
            <li
              class="category_column"
              v-for="(categories, index) in state.menuCategoroiesOther"
              :key="index"
            >
              <div v-for="(category, i) in categories" :key="i">
                <dl>
                  <dt>
                    <router-link
                      :to="{
                        path: _categoryUrl(category),
                        query: { type_category: 'other' },
                      }"
                      :title="`${category.category}`"
                    >
                      <i :class="['dsi', getCategoryIcon(category.category_id)]"></i>
                      <span>{{ category.category }}</span>
                    </router-link>
                  </dt>
                  <div v-if="category.subcategories">
                    <dd v-for="(subCat, i) in category.subcategories" :key="i">
                      <router-link
                        class="dosiin_d-flex"
                        :to="{
                          path: _categoryUrl(subCat),
                          query: { type_category: 'other' },
                        }"
                        :title="`${subCat.category}`"
                      >
                        <span>{{ subCat.category }}</span>
                      </router-link>
                    </dd>
                  </div>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { reactive, inject, toRefs } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import CategoryService from "@/services/category";
import vClickOutside from "click-outside-vue3";
import Modal from "@/components/modals/Modal";
import PromotionService from "@/services/promotion";
import { Autoplay } from "swiper";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Modal,
  },
  props: {
    options: {
      type: Object,
      default: {
        direction: "vertical",
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        centeredSlides: true,
        autoplay: {
          delay: 2000,
        },
      },
    },
  },
  setup(props) {
    const options = {};
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      loadingCat: true,
      showCategory: false,
      showCategoryMale: false,
      showCategoryFemale: false,
      showCategoryOther: false,
      promotions: [],
      menuCategoroies: [],
      menuCategoroiesMale: [],
      menuCategoroiesFemale: [],
      menuCategoroiesOther: [],
    });

    const currentMonth = inject("currentMonth");
    allCategoryLevel();
    allCategoryLevelMale();
    allCategoryLevelFeMale();
    allCategoryLevelOther();
    getPromos();

    const showCategory = (type) => {
      if (type == "all") state.showCategory = true;
      if (type == "male") state.showCategoryMale = true;
      if (type == "female") state.showCategoryFemale = true;
      if (type == "other") state.showCategoryOther = true;
      document.body.classList.add("no-scroll");
      document.body.classList.add("dosiin_overlay-body");
      document.body.classList.add("dosiin_modal-categories");
    };

    const hideCategory = (type) => {
      if (type == "all") state.showCategory = false;
      if (type == "male") state.showCategoryMale = false;
      if (type == "female") state.showCategoryFemale = false;
      if (type == "other") state.showCategoryOther = false;
      document.body.classList.remove("no-scroll");
      document.body.classList.remove("dosiin_overlay-body");
      document.body.classList.remove("dosiin_modal-categories");
    };

    async function allCategoryLevel() {
      const response = await CategoryService.fetchCategories(
        "?menu=true&sort_by=position"
      );

      if (response.data) {
        let column = 5;
        let categories = response.data;
        let columnSize = Math.ceil(categories.length / column);
        for (var i = 0; i < column; i++) {
          state.menuCategoroies.push(
            categories.slice(i * columnSize, i * columnSize + columnSize)
          );
        }
      }

      state.loadingCat = false;
    }

    async function allCategoryLevelMale() {
      const response = await CategoryService.fetchCategories(
        "?menu=true&sort_by=position&type_category=male"
      );

      if (response.data) {
        let column = 5;
        let categories = response.data;
        let columnSize = Math.ceil(categories.length / column);
        for (var i = 0; i < column; i++) {
          state.menuCategoroiesMale.push(
            categories.slice(i * columnSize, i * columnSize + columnSize)
          );
        }
      }
    }

    async function allCategoryLevelFeMale() {
      const response = await CategoryService.fetchCategories(
        "?menu=true&sort_by=position&type_category=female"
      );

      if (response.data) {
        let column = 5;
        let categories = response.data;
        let columnSize = Math.ceil(categories.length / column);
        for (var i = 0; i < column; i++) {
          state.menuCategoroiesFemale.push(
            categories.slice(i * columnSize, i * columnSize + columnSize)
          );
        }
      }
    }

    async function allCategoryLevelOther() {
      const response = await CategoryService.fetchCategories(
        "?menu=true&sort_by=position&type_category=other"
      );

      if (response.data) {
        let column = 5;
        let categories = response.data;
        let columnSize = Math.ceil(categories.length / column);
        for (var i = 0; i < column; i++) {
          state.menuCategoroiesOther.push(
            categories.slice(i * columnSize, i * columnSize + columnSize)
          );
        }
      }
    }

    async function getPromos() {
      const response = await PromotionService.fetchPromotions({ company_id: 0 });
      if (response.data) {
        state.promotions = response.data.promotions;
      }
    }

    // same as beforeRouteUpdate option with no access to `this`
    onBeforeRouteUpdate(async (to, from) => {
      state.showCategory = false;
      state.showCategoryFemale = false;
      state.showCategoryMale = false;
      state.showCategoryOther = false;
      document.body.classList.remove("no-scroll");
      document.body.classList.remove("dosiin_overlay-body");
      document.body.classList.remove("dosiin_modal-categories");
    });

    return {
      options,
      route,
      router,
      state,
      currentMonth,
      showCategory,
      hideCategory,
      ...toRefs(props),
      modules: [Autoplay],
    };
  },
};
</script>

<style>
.dosiin_modal-categories #modals {
  top: 125px !important;
  z-index: 11 !important;
  height: 100vh !important;
  width: 100% !important;
  background: unset !important;
  overflow-y: scroll;
  transition: all 0.2s ease-in-out;
}

.modal.dosiin_modal-aside {
  height: 100% !important;
  top: 0;
}

.dsi_header-tab-icon span {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(72, 72, 72, 1);
}
</style>
