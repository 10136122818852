<template>
  <Teleport to="#modals">
    <Transition duration="500" name="nested">
      <div :class="['modal', wrapperClass, show ? 'open' : '']">
        <div class="dosiin_filter-modal filter-modal" :class="modalTypeClass">
          <div class="modal-header" style="display: unset">
            <div class="back-header dosiin_back-header-sticky">
              <div class="go-back-icon">
                <button class="text" @click="closeModal">
                  <i class="dsi dsi-act-close-circle dsi-s28"></i>
                </button>
              </div>
              <slot name="header"></slot>
            </div>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="footer modal-footer footer-offset">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import vClickOutside from "click-outside-vue3";
import { useStore } from "vuex";
export default {
  name: "Modal",
  emits: ["close", "update:modalValue"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    close: {
      type: Function,
      default: () => undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    modalTypeClass: {
      type: String,
      default: "modal-full-screen",
    },
    overlay: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(props.modalValue);
    const store = useStore();

    onMounted(() => {
      store.dispatch("modal/initModal");
    });

    watch(
      () => props.modelValue,
      (isOpen) => {
        show.value = isOpen;
        if (show.value == true) store.dispatch("modal/increase");
        else store.dispatch("modal/decrease");
      }
    );

    const closeModal = () => {
      show.value = !show.value;
      emit("update:modalValue", show.value);
      emit("close");
    };

    return {
      show,
      closeModal,
    };
  },
};
</script>

<style>
.modal {
  top: 0;
  left: 0;
  position: fixed;
}

.modal.open .modal-full-screen {
  z-index: 999 !important;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #fff;
}

.modal.modal-full-screen {
  z-index: 999 !important;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #fff;
}

.modal.modal-medium-screen {
  z-index: 999 !important;
  width: 50%;
  height: 100%;
  display: block;
  background-color: #fff;
}

.modal-sidebar-right {
  position: fixed;
  width: 80%;
  min-width: 292px;
  height: 100%;
  top: 0;
  right: 0 !important;
  background: rgba(255, 255, 255);
  display: block;
  z-index: 110;
}

.modal_filter-inner {
  display: block !important;
}

.nested-enter-active,
.nested-leave-active {
  transition: all 0.2s ease-in-out;
}
/* delay leave of parent element */
/* .nested-leave-active {
        transition-delay: 0.1s;
    } */

.nested-enter-from,
.nested-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
