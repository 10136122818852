<template>
  <div class="dosiin_home home-page main-page" :style="route.name !== 'exclusive' ? `padding-bottom: 56px` : ''">
    <RootMenu v-if="state.showHeader" />
    <router-view v-slot="{ Component }">
      <keep-alive
        :include="['styles', 'shops', 'brand', 'home', 'DailySuggest', 'AllProducts']"
      >
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <FooterMenu v-if="state.showFooter" />
  </div>
</template>

<script>
import RootMenu from "@/components/menus/RootMenu";
import FooterMenu from "@/components/menus/MainMenuMB";
import { ref, watch, reactive } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "LayoutMB",
  components: {
    RootMenu,
    FooterMenu,
  },
  setup() {
    const route = useRoute();
    const routeName = ref(route.name);
    const headerInRoute = [
      "style",
      "shops",
      "events",
      "ranking",
      "home-home",
      "brand",
      "on-sale",
      "musinsa"
    ];
    const footerInroute = [
      "shops",
      "ranking",
      "events",
      "home-home",
      "brand",
      "newest",
      "notifications-home",
      "on-sale",
      "page",
      "exclusive",
      "musinsa"
    ];
    const state = reactive({
      showHeader: false,
      showFooter: false,
    });

    if (headerInRoute.includes(routeName.value)) {
      state.showHeader = true;
    }

    if (footerInroute.includes(routeName.value)) {
      state.showFooter = true;
    }

    watch(
      () => route.name,
      (newName) => {
        routeName.value = newName;
        if (headerInRoute.includes(routeName.value)) {
          state.showHeader = true;
        } else {
          state.showHeader = false;
        }

        if (footerInroute.includes(routeName.value)) {
          state.showFooter = true;
        } else {
          state.showFooter = false;
        }
      }
    );

    return {
      state,
      routeName,
      route
    };
  },
};
</script>
