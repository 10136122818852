<template>
    <div :class="wrapperClass">
        <img 
            class="dosiin_w-100"
            :class="imageClass"
            v-lazy="{ src: _bannerImage(pairsObject), loading: _bannerDefault, error: _bannerDefault}"
        /> 
    </div>
</template>

<script>
    import { ref } from '@vue/reactivity';
    import { watch } from '@vue/runtime-core';
    
    export default {
        props:{
            wrapperClass : {
                type : String,
                default : '' 
            },
            imageClass : {
                type : String,
                default : ''
            },
            pairsObject :{
                type : [Object , String],
                require : true 
            }
        },  
        setup(props) {
            
            const pairsObject = ref(props.pairsObject);
                
            watch(() => props.pairsObject , (newPair) => {
                pairsObject.value = newPair;
            });

            return {
                pairsObject
            }
        },
    }
</script>