import { createStore, createLogger } from "vuex";
import PostsStore from "./PostsStore/index";
import AccountStore from "./account";
import Cart from "./cart";
import Notifications from "./notification";
import Modal from "./modal";
const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    modal: Modal,
    PostsStore: PostsStore,
    account: AccountStore,
    cart: Cart,
    notification: Notifications,
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
});
