import { useRouter , useRoute } from 'vue-router';

const MainRoute = '/';

// import { accountService } from '@/_services';
// import { router } from '@/route';

// export function authGuard(to) {
//     const account = accountService.accountValue;
//     if (account) {
//         // logged in so return true
//         return true;
//     }

//     // not logged in so redirect to login page with the return url
//     router.push({ path: '/login', query: { returnUrl: to.fullPath } });
//     return false;
// }

// export function authAfterLogin(user){
//     const route  = useRoute();
//     const router = useRouter();
//     // localStorage.setItem('user', JSON.stringify(user));
//     if(route.query.next){
//         router.push(route.query.next);
//     }else{
//         router.push(MainRoute);
//     }
// }

export const authRoute = [
    'login',
    'login-sms',
    'register',
    'reset-password'
];