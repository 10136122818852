import { createApp } from 'vue';
import App from './App';
// const { createSSRApp } = require('vue');
// const { renderToString } = require('@vue/server-renderer')
// const server = require('express')();
// import { createMetaManager, plugin as metaPlugin } from 'vue-meta';

import router from './router/index';
import Mixin from './mixin';
import store from './store/index';
import filters from './filters';
import InstantSearch from 'vue-instantsearch/vue3/es';
// libs
import moment from 'moment';
import PulseLoader from '@/components/common/PulseLoader';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import Toast , { TYPE } from "vue-toastification";
import i18n from './i18n';
import VueLazyLoad from 'vue3-lazyload'
import VueSkeletor ,{ Skeletor } from 'vue-skeletor';
import GAuth from '@/plugins/google';
import { initializeApp } from 'firebase/app';
import VueCountdown from '@chenfengyuan/vue-countdown';

// css libs
import "vue-toastification/dist/index.css";
import '@/assets/css/plugins/swiper/swiper-bundle.min.css';
require('vue-skeletor/dist/vue-skeletor.css');





const app = createApp(App);

if(Tygh.layout != 4){
    require("@/assets/css/pc/app.min.css");
}else{
    // imyport("@/assets/css/mb/app.min.css");
}

const gAuthOptions = { clientId: '653952413372-ckqgja7sh16f21ha2qgj5e3rabr36b0b.apps.googleusercontent.com', scope: 'email', prompt: 'consent', fetch_basic_profile: false }
const optionsToast = {
    position: "bottom-right",
    transition: "Vue-Toastification__fade",
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
    maxToasts:2,
    toastDefaults: {
        [TYPE.ERROR]: {
            closeButton: false,
            hideProgressBar: false,
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
            hideProgressBar: false,
        }    
    },
    newestOnTop: true,
    filterBeforeCreate: (toast, toasts) => {
        if (toasts.filter(
          t => t.type === toast.type
        ).length !== 0) {
          // Returning false discards the toast
          return false;
        }
        // You can modify the toast if you want
        return toast;
    }
};

const optionsLazyLoad = {
    loading: 'https://dosi-in.com/images/assets/default-dosiin-product.png',
    error: 'https://dosi-in.com/images/assets/default-dosiin-product.png',
    lifecycle:{
        loading: (el) => {},
        error: (el) => {},
        loaded: (el) => {}
    }
}

const firebaseConfig = {
    apiKey: "AIzaSyALEL8H-eP4pMmYNQ42QEFAq1UIgMvB6fc",
    authDomain: "dosi-in.firebaseapp.com",
    projectId: "dosi-in",
    storageBucket: "dosi-in.appspot.com",
    messagingSenderId: "465248494556",
    appId: "1:465248494556:web:7566d9c3c8b9d7e5512519",
    measurementId: "G-JMTFSES648"
};
// Initialize Firebase
initializeApp(firebaseConfig); 

// global uses 
app.use(i18n);
app.use(InstantSearch);
app.use(router);
app.use(store);
app.use(Toast, optionsToast);
app.use(VueLazyLoad , optionsLazyLoad);
app.use(VueSkeletor, {shimmer: true,});
// app.use(createMetaManager());
app.use(GAuth, gAuthOptions)

// global components
import LikeButton from '@/components/buttons/LikeButton';
import CommentButton from '@/components/buttons/CommentButton';
import FollowButton from '@/components/buttons/FollowButton';
import ShareButtonModal from '@/components/buttons/ShareButtonModal';
import ShareButtonDropdown from '@/components/buttons/ShareButtonDropdown';
import Banner from '@/components/images/Banner';
import BrandLogo from '@/components/images/BrandLogo';
import Intersection from '@/components/common/Intersection';
import Breadcrumbs from '@/components/common/Breadcrumbs';
import LoadingComponent from '@/components/common/Loading';
import EmptyComponent from '@/components/common/EmptyData';
// layout components
import Footer from '@/layouts/pc/FooterPC';
import Header from '@/layouts/pc/Header';

import Tabs from '@/components/tabs/Tabs';
import Tab from '@/components/tabs/Tab';

app.component('Banner',Banner);
app.component('LikeButton', LikeButton);
app.component('CommentButton', CommentButton);
app.component('FollowButton', FollowButton);
app.component('ShareButtonModal', ShareButtonModal);
app.component('ShareButtonDropdown' , ShareButtonDropdown);
app.component('Tabs', Tabs);
app.component('Tab', Tab);
app.component('PulseLoader' , PulseLoader);
app.component('Swiper', Swiper);
app.component('SwiperSlide' , SwiperSlide);
app.component(Skeletor.name, Skeletor);
app.component('footer-pc' , Footer);
app.component('header-pc' , Header);
app.component(VueCountdown.name, VueCountdown);
app.component('Intersection' , Intersection);
app.component('Breadcrumbs' , Breadcrumbs);
app.component('LoadingComponent' , LoadingComponent);
app.component('EmptyComponent',EmptyComponent);
app.component('BrandLogo',BrandLogo);

app.mixin(Mixin);
// globals properties
app.config.globalProperties.$filters = filters;

// provide 
app.provide('mainRoute', 'home-home');
app.mount('#app');
// const appContent = await renderToString(app);
// router.isReady().then(() => app.mount('#app'))
