import Api from '@/services/Api';

export default {
    like(payload) {
        return Api().post('/likeable' ,payload);
    },
    unlike(payload) {
        return Api().put('/likeable/' + payload.liker_id  ,payload);
    },
}
