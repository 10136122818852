<template>
  <button
    v-if="typeButton == 'button'"
    @click="comment"
    class="more-btn dosiin_btn-open-modal dosiin_reply-btn action-btn reply-btn d-flex align-items-center justify-content-between"
    href="./"
    title="Xem thêm"
    value="#dosiin_review"
  >
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66669 3.4784V18.8333L4.4095 16.0836H17.2328C17.8376 16.0836 18.3334 15.5867 18.3334 14.9802V3.4784C18.3334 2.87186 17.8376 2.375 17.2328 2.375H2.7672C2.16244 2.375 1.66669 2.87186 1.66669 3.4784ZM17.2328 15.377H4.11779L2.37126 17.128V3.4784C2.37126 3.25631 2.54542 3.08156 2.7672 3.08156H17.2328C17.4546 3.08156 17.6288 3.25631 17.6288 3.4784V14.9802C17.6288 15.2023 17.4546 15.377 17.2328 15.377ZM6.50831 7.87585C5.76582 7.87585 5.15839 8.48467 5.15839 9.22929C5.15839 9.97391 5.76582 10.5827 6.50831 10.5827C7.2508 10.5827 7.85823 9.97391 7.85823 9.22929C7.85823 8.48467 7.2508 7.87585 6.50831 7.87585ZM10 7.87585C9.25753 7.87585 8.6501 8.48467 8.6501 9.22929C8.6501 9.97391 9.25753 10.5827 10 10.5827C10.7425 10.5827 11.3499 9.97391 11.3499 9.22929C11.3499 8.48467 10.7425 7.87585 10 7.87585ZM13.4917 7.87585C12.7492 7.87585 12.1418 8.48467 12.1418 9.22929C12.1418 9.97391 12.7492 10.5827 13.4917 10.5827C14.2342 10.5827 14.8416 9.97391 14.8416 9.22929C14.8416 8.48467 14.2342 7.87585 13.4917 7.87585ZM6.50831 8.58242C6.86778 8.58242 7.15366 8.86917 7.15366 9.22929C7.15366 9.58941 6.86778 9.87616 6.50831 9.87616C6.14885 9.87616 5.86297 9.58941 5.86297 9.22929C5.86297 8.86917 6.14885 8.58242 6.50831 8.58242ZM10 8.58242C10.3595 8.58242 10.6454 8.86917 10.6454 9.22929C10.6454 9.58941 10.3595 9.87616 10 9.87616C9.64056 9.87616 9.35468 9.58941 9.35468 9.22929C9.35468 8.86917 9.64056 8.58242 10 8.58242ZM13.4917 8.58242C13.8512 8.58242 14.1371 8.86917 14.1371 9.22929C14.1371 9.58941 13.8512 9.87616 13.4917 9.87616C13.1323 9.87616 12.8464 9.58941 12.8464 9.22929C12.8464 8.86917 13.1323 8.58242 13.4917 8.58242Z"
        fill="#E6E6E6"
      />
    </svg>
    <span class="dosiin_ml-2">{{ discussionCount }}</span>
  </button>
  <button
    v-else
    class="more-btn dosiin_reply-btn comment"
    title="Viết đánh giá"
    @click="comment"
  >
    <i :class="['dsi dsi-comment', pageComment ? 'dosiin-hidden' : '']"></i>
    {{ titleComment }}
  </button>
</template>

<script>
import { onMounted, computed, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  props: {
    isType: {
      type: String,
    },
    vnfd_id: {
      type: [Number, String],
    },
    typeButton: {
      type: String,
    },
    url: {
      type: String,
    },
  },
  emits: ["canBeComment"],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const discussionCount = ref(0);
    const pageComment = ref(false);
    const titleComment = ref("Bình luận");
    const store = useStore();
    const loggedIn = computed(() => store.state.account.status.loggedIn);
    const openModalLogin = inject("openModalLogin");
    onMounted(() => {
      if (route.name === "detail" && Tygh.layout == 4) {
        titleComment.value = "";
        pageComment.value = true;
      }
    });

    const comment = () => {
      if (props.isType == "link") {
        router.push(props.url);
      } else {
        if (!loggedIn.value) {
          openModalLogin();
          return;
        }
      }
    };

    return {
      discussionCount,
      pageComment,
      titleComment,
      comment,
    };
  },
};
</script>
