<template>
  <router-link
    :to="
      brand.seo_name ? _brandUrl(brand.seo_name) : _brandUrl(brand.company_data.seo_name)
    "
    :class="linkClass"
  >
    <img
      :width="width"
      :height="height"
      v-lazy="_companyAvatar(brand.logos.theme)"
      :class="imageClass"
      :alt="brand.company"
    />
  </router-link>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 62,
    },
    height: {
      type: Number,
      default: 62,
    },
    brand: {
      type: Object,
      default: {},
    },
    linkClass: {
      type: String,
      default: "",
    },
    imageClass: {
      type: String,
      default: "brand-logo_img",
    },
  },
};
</script>
