import axios from "axios";
import { _getCookie } from "@/utlis/cookie";
import { useToast } from "vue-toastification";
import authHeader from "@/services/AuthHeader";
import router from "@/router";

export default () => {
  let api = axios.create({
    baseURL: "/api",
    withCredentials: true,
    headers: authHeader(),
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;

      if (!response) {
        // network error
        console.error(error);
        return;
      }

      if ([401, 403].includes(response.status)) {
        // auto logout if 401 or 403 response returned from api
        router.push({
          name: "login",
          query: { next: encodeURIComponent(window.location.href) },
        });
      }

      // whatever you want to do with the error
      const toast = useToast();
      if (response && response.data) {
        toast.error(response.data.message);
      }
    }
  );

  return api;
};
