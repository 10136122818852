<template>
  <div ref="root">
    <slot></slot>
  </div>
</template>

<script>
    import { ref , onMounted, onUnmounted } from 'vue';
    export default {
        name: "observer",
        props: {
            root: {
                type: [Object],
                default: null
            },
            rootMargin: {
                type: [String, Number],
                default: "100px"
            },
            threshold: {
                type: [Array, Number],
                default: 0
            }
        },
        emits:['onIntersection'],
        setup(props , {emit}){
            const root = ref(null)
            var observer = null;

            onMounted(() => {
                observer = new IntersectionObserver(entries => {
                    if(entries[0].isIntersecting){
                        emit('onIntersection' ,entries[0] , unobserve());
                    }
                });
            
                observer.observe(root.value);
            });
            
            onUnmounted(() =>{
                 if (observer.value) {
                    unobserve();
                    observer = null;
                }
            });

            function unobserve() {
                observer.unobserve(root.value);
            }

            return {
                root,
                observer,
                // methods
                unobserve
            };
        },
        beforeDestroy() {
           
        }
    };
</script>