<template>
  <header class="dosiin_headers dosiin-header-sticky" id="dosiin_header">
    <div class="ds_top-header">
      <div class="ds_top-header_inner dosiin_d-flex">
        <div class="ds_top-header_item dsi_header-left dosiin_d-flex">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.81664 2.7305L7.81686 2.73033C7.92705 2.64545 8.07888 2.64545 8.18907 2.73033L8.18929 2.7305L14.5499 7.67264C14.6878 7.78656 14.7012 7.98353 14.6025 8.10732C14.5026 8.23258 14.3173 8.2605 14.1788 8.15698L14.178 8.15633L12.8299 7.1175V13.028C12.8299 13.1983 12.6953 13.3333 12.5255 13.3333H3.48043C3.31061 13.3333 3.17603 13.1983 3.17603 13.028V7.1175L1.82135 8.16141L1.81692 8.16522C1.75993 8.21423 1.71067 8.22904 1.63953 8.22904C1.55248 8.22904 1.4734 8.19226 1.41178 8.10395L1.40533 8.09471L1.39797 8.08618C1.29657 7.96881 1.31455 7.77917 1.45109 7.67644L1.45244 7.67543L7.81664 2.7305ZM3.78483 12.7226H12.2211V6.62431L8.00265 3.34292L3.78483 6.63916V12.7226Z"
              fill="#B3B3B3"
            ></path>
            <path
              d="M7.72553 4.94444L5.97776 6.51481C5.89109 6.6037 5.83331 6.72222 5.83331 6.84074V10.737C5.83331 10.9741 6.02109 11.1667 6.2522 11.1667H9.74776C9.97887 11.1667 10.1666 10.9741 10.1666 10.737V6.84074C10.1666 6.72222 10.1089 6.58888 10.0222 6.51481L8.27442 4.94444C8.11554 4.79629 7.88442 4.79629 7.72553 4.94444ZM8.70776 8.35185C8.70776 8.36666 8.70776 8.39629 8.69331 8.41111C8.67887 8.42592 8.66442 8.44074 8.63554 8.44074H8.37553C8.34665 8.44074 8.31776 8.41111 8.30331 8.38148C8.28887 8.3074 8.21665 8.24814 8.14442 8.24814H7.91331C7.78331 8.24814 7.66776 8.35185 7.66776 8.47037C7.66776 8.54444 7.6822 8.6037 7.72553 8.66296C7.76887 8.7074 7.84109 8.73703 7.89887 8.73703H8.0722C8.41887 8.73703 8.70776 9.0037 8.73665 9.32962C8.76554 9.68518 8.53442 9.99629 8.2022 10.0407V10.2926C8.2022 10.337 8.17331 10.3667 8.12998 10.3667H7.86998C7.82665 10.3667 7.79776 10.337 7.79776 10.2926V10.0407C7.52331 10.0111 7.32109 9.8037 7.2922 9.52222C7.2922 9.5074 7.2922 9.47777 7.30665 9.46296C7.32109 9.44814 7.33553 9.43333 7.36442 9.43333H7.62442C7.65331 9.43333 7.6822 9.46296 7.69665 9.49259C7.71109 9.56666 7.78331 9.62592 7.85554 9.62592H8.08665C8.21665 9.62592 8.3322 9.52222 8.34665 9.4037C8.34665 9.32962 8.3322 9.27037 8.28887 9.21111C8.24554 9.16666 8.17331 9.13703 8.11554 9.13703H7.91331C7.72554 9.13703 7.5522 9.06296 7.43665 8.91481C7.30665 8.78148 7.24887 8.58888 7.27776 8.41111C7.30665 8.12962 7.52331 7.89259 7.8122 7.83333V7.58148C7.8122 7.53703 7.84109 7.5074 7.88442 7.5074H8.14442C8.18776 7.5074 8.21665 7.53703 8.21665 7.58148V7.83333C8.47665 7.86296 8.67887 8.07037 8.70776 8.35185ZM8.40442 6.36666C8.40442 6.6037 8.21665 6.78148 7.99998 6.78148C7.76887 6.78148 7.59554 6.58888 7.59554 6.36666C7.59554 6.12962 7.78331 5.95185 7.99998 5.95185C8.23109 5.93703 8.40442 6.12962 8.40442 6.36666Z"
              fill="#B3B3B3"
            ></path>
          </svg>
          <a href="https://dosi-in.com/hop-tac" class="ds_top-header_txt"
            >Bán hàng cùng Dosiin</a
          >
        </div>
        <div class="dsi_header-center-box dosiin_d-flex">
          <Swiper
            :modules="modules"
            :direction="'vertical'"
            :loop="options.loop"
            :autoplay="options.autoplay"
            :centeredSlides="options.centeredSlides"
            :slidesPerView="options.slidesPerView"
          >
            <SwiperSlide v-for="(promo, index) in state.promotions" :key="index">
              <a :href="promo.link">
                <div class="home-event-menu d-flex align-items-center">
                  <div class="dsi_header-left-month-icon">
                    <i class="dsi dsi-act-fill-coupons-n"></i>
                  </div>
                  <p class="dsi_header-txtm">
                    <span class="dsi_header-text">Tháng {{ currentMonth }} </span>
                    <span
                      class="dsi_header-text dsi_header-color"
                      v-text="promo.short_description"
                    ></span>
                    <span class="dsi_header-text">cùng Dosiin</span>
                  </p>
                </div>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="ds_top-header_item dosiin_d-flex dsi_header-right">
          <div class="ds_top-header_info dosiin_mr-2">
            <p>
              <a
                class="dosiin_d-block"
                :href="`tel:${env.VUE_APP_HOTLINE}`"
                :title="`Hotline: ${env.VUE_APP_HOTLINE}`"
                >Hotline: {{ env.VUE_APP_HOTLINE }}</a
              >
            </p>
          </div>
          <div class="ds_top-header_info dosiin_ml-2">
            <a href="https://m.me/dosiinvietnam" class="d-flex align-items-center">
              <i
                class="dsi dsi-fill-messenger dsi-s16 dosiin_mr-2"
                style="
                  filter: invert(82%) sepia(3%) saturate(11%) hue-rotate(37deg)
                    brightness(90%) contrast(83%);
                "
              ></i>
              Hỗ trợ trực tuyến
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="dosiin_header dosiin_d-flex">
      <div class="dosiin_header-left">
        <ul class="dosiin_header-left-list dosiin_d-flex">
          <li class="dosiin_header-left-item">
            <a href="/" title="logo">
              <img :src="_logo" alt="Sàn thương mại điện tử dosi-in" />
            </a>
          </li>
          <div class="dosiin_header-left-divider"></div>
          <li
            class="dosiin_header-left-item"
            :class="{ is_active: route.meta.isShopping }"
          >
            <router-link :to="{ name: 'home-home' }" title="shopping"
              >Shopping</router-link
            >
          </li>
          <div class="dosiin_header-left-divider"></div>
          <li class="dosiin_header-left-item" :class="{ is_active: route.meta.isStyle }">
            <router-link :to="{ name: 'style' }" title="Dosi-in Style">
              Style
            </router-link>
          </li>
          <div class="dosiin_header-left-divider"></div>
        </ul>
      </div>
      <div class="dosiin_header-middle">
        <div class="dosiin_header-middle-search dosiin_d-flex">
          <button class="dosiin_header-middle-icon" v-if="!keyword || keyword == ''">
            <i class="dsi dsi-search"></i>
          </button>
          <input
            class="dosiin_header-middle-ipt"
            @focus="onSearchStart"
            type="text"
            v-model="keyword"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            ref="searchInput"
            placeholder="Nhập từ khoá tìm kiếm"
            @keyup="onSearchKeyup($event.currentTarget.value)"
          />
          <button
            class="dosiin_header-middle-icon"
            v-if="keyword && keyword.length > 0"
            @click="
              keyword = '';
              onSearchKeyup('');
            "
          >
            <i class="dsi dsi-close-circle"></i>
          </button>
        </div>
      </div>
      <div class="dosiin_header-right dosiin_d-flex">
        <div class="dosiin_header-left-divider"></div>
        <ul class="dosiin_header-right-list dosiin_d-flex">
          <!-- <li class="dosiin_header-right-item">
                        <div class="dosiin_header-right-icon"><i class="dsi dsi-s24 dsi-camera-plus"></i></div>
                    </li> -->
          <NotificationHeader />
          <li class="dosiin_header-right-item">
            <a href="/cart">
              <div class="dosiin_header-right-icon">
                <i class="dsi dsi-s24 dsi-cart"></i>
              </div>
              <div class="dosiin_header-right-nofication" v-if="cartAmount > 0">
                <span v-text="cartAmount"></span>
              </div>
            </a>
          </li>
        </ul>
        <div
          class="dosiin_header-right-info dosiin_d-flex"
          v-click-outside="closeMenuLogin"
        >
          <template v-if="currentUser">
            <div class="dosiin_header-right-avatar" @click="showMenuLogin">
              <img
                v-lazy="_userAvatar(currentUser.avatar)"
                alt="avatar"
                width="40"
                height="40"
              />
            </div>
            <div class="dosiin_header-right-name" @click="showMenuLogin">
              <span v-text="$t('account.user')" :title="$t('account.user')"></span>
              <p>{{ currentUser.firstname }}</p>
            </div>
          </template>

          <template v-else>
            <div class="dosiin_header-right-avatar" @click="showMenuLogin">
              <img :src="_userNoAvatar" alt="avatar" width="40" height="40" />
            </div>

            <div class="no_login-inner" @click="showMenuLogin">
              <span v-text="$t('account.user')" :title="$t('account.user')"></span>
              <p>Xin chào!</p>
            </div>
          </template>
          <div class="dosiin_header-right-dow" @click="showMenuLogin">
            <i class="dsi dsi-drop-down"></i>
            <Teleport to="#modals">
              <div
                :class="['login_avatar ', showMenu ? 'login_avatar_active' : '']"
                id="login_avatar"
              >
                <div class="login_avatar-inner">
                  <div class="aside_bar-inner">
                    <ul class="login_avatar-list">
                      <template v-if="currentUser">
                        <li class="aside_bar-item">
                          <div class="aside_bar-item-box">
                            <a
                              class="dosiin_d-block"
                              href="/user-profile"
                              :title="$t('account.order_info')"
                            >
                              <div class="aside_bar-item-category">
                                <span v-text="$t('account.order_info')"></span>
                              </div>
                            </a>
                          </div>
                        </li>
                        <li class="aside_bar-item" @click.prevent="logOut">
                          <div class="aside_bar-item-box">
                            <a class="dosiin_d-block" :title="$t('account.logout')">
                              <div class="aside_bar-item-category">
                                <span v-text="$t('account.logout')"></span>
                              </div>
                            </a>
                          </div>
                        </li>
                      </template>
                      <template v-else>
                        <li class="aside_bar-item">
                          <div class="aside_bar-item-box">
                            <router-link
                              :to="currentUrl"
                              class="dosiin_d-block"
                              :title="$t('account.login')"
                            >
                              <div class="aside_bar-item-category">
                                <span
                                  v-text="$t('account.login')"
                                  :title="$t('account.login')"
                                ></span>
                              </div>
                            </router-link>
                          </div>
                        </li>
                        <li class="aside_bar-item">
                          <div class="aside_bar-item-box">
                            <router-link
                              :to="currentUrlRegister"
                              class="dosiin_d-block"
                              :title="$t('account.register')"
                            >
                              <div class="aside_bar-item-category">
                                <span
                                  v-text="$t('account.register')"
                                  :title="$t('account.register')"
                                ></span>
                              </div>
                            </router-link>
                          </div>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </Teleport>
          </div>
        </div>
      </div>
    </div>
    <BrandMenu v-if="route.meta.isBrandPage && route.meta.isShopping" />
    <ShopMenu v-else-if="route.meta.isShopping" />
  </header>
</template>
<script>
import { computed, ref, watch, inject, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import NotificationHeader from "@/components/notification/NotificationHeader";
import { authRoute } from "@/router/authGuard";
import vClickOutside from "click-outside-vue3";
import ShopMenu from "@/components/menus/ShopMenu";
import BrandMenu from "@/components/menus/BrandMenu";
import PromotionService from "@/services/promotion";
import { Autoplay } from "swiper";
export default {
  name: "Header",
  components: {
    NotificationHeader,
    ShopMenu,
    BrandMenu,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    options: {
      type: Object,
      default: {
        direction: "vertical",
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        centeredSlides: true,
        autoplay: {
          delay: 2000,
        },
      },
    },
  },
  setup(props) {
    const options = {};
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const showMenu = ref(false);
    const products = computed(() => store.getters["cart/cartProducts"]);
    const total = computed(() => store.getters["cart/cartTotalPrice"]);
    const currentUser = inject("currentUser");
    const userToken = computed(() => store.getters["account/getUserToken"]);
    const cartAmount = computed(() => store.getters["cart/cartTotalItems"]);
    const routeName = computed(() => route.name);
    const statusUser = computed(() => store.state.AccountStore.status);
    const currentUrl = ref("/login");
    const currentUrlRegister = ref("/register");
    const keyword = ref(route.query.keyword ? route.query.keyword : "");
    const logOut = inject("logOut");
    const env = process.env;
    const state = reactive({
      isSticky: false,
      promotions: [],
    });

    getPromos();

    const currentMonth = inject("currentMonth");

    function onSearchStart() {
      router.push({
        name: "search",
        query: route.query,
      });
    }

    const showMenuLogin = () => {
      showMenu.value = !showMenu.value;
    };

    const closeMenuLogin = () => {
      showMenu.value = false;
    };

    async function getPromos() {
      const response = await PromotionService.fetchPromotions({ company_id: 0 });
      if (response.data) {
        state.promotions = response.data.promotions;
      }
    }

    const onSearchKeyup = (value) => {
      router.replace({
        name: "search",
        query: {
          keyword: value,
          tab: route.query.tab,
        },
      });
    };

    watch(
      () => route.name,
      () => {
        // clear keyword
        if (route.name != "search") {
          keyword.value = "";
        }

        if (!authRoute.includes(route.name)) {
          currentUrl.value = `/login?next=${encodeURIComponent(
            window.location.origin + window.location.pathname
          )}`;
          currentUrlRegister.value = `/register?next=${encodeURIComponent(
            window.location.origin + window.location.pathname
          )}`;
        } else {
          currentUrl.value = "/login";
          currentUrlRegister.value = "/register";
        }
      },
      { immediate: true }
    );

    watch(
      () => route.query,
      (newQuery) => {
        keyword.value = newQuery.keyword;
      }
    );

    return {
      options,
      state,
      route,
      keyword,
      currentUrl,
      currentUrlRegister,
      products,
      total,
      env,
      // checkout,
      currentUser,
      userToken,
      cartAmount,
      routeName,
      showMenu,
      statusUser,
      //methos
      logOut,
      showMenuLogin,
      closeMenuLogin,
      onSearchStart,
      onSearchKeyup,
      ...toRefs(props),
      modules: [Autoplay],
      currentMonth,
    };
  },
};
</script>

<style>
.dosiin-header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  background: rgb(255 255 255 / 80%);
  box-shadow: 0px 4px 8px rgb(16 16 16 / 5%);
  backdrop-filter: saturate(180%) blur(40px);
}
</style>
