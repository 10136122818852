import Api from '@/services/Api';

export default {
    fetchCategories(query) {
        return Api().get('/categories' + query);
    },

    fetchCategory(id){
        return Api().get(`/categories/${id}`);
    }
}