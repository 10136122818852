<template>
  <div class="dsi_header-inner">
    <a
      v-if="router.options.history.state.back && $route.name != 'home-home'"
      href="/"
      @click.prevent="goBack"
      class="header-come-back"
    >
      <i class="dsi dsi-chevron-left"> </i>
      <span class="text-back">Quay lại</span>
    </a>

    <a v-else class="header-come-back"></a>

    <div class="dsi_header-center">
      <ul class="dsi_header-tab-list dosiin_d-flex m-menu">
        <li
          class="m-menu-sub"
          id="m-menu-sub"
          :class="{
            is_active: !$route.query.t,
          }"
        >
          <router-link :to="_brandUrl(brandUrl)" replace>
            <span class="m-menu-ttl">Tổng quát</span>
          </router-link>
        </li>

        <li
          class="m-menu-sub"
          id="m-menu-sub"
          :class="{
            is_active: $route.query.t === 'store',
          }"
        >
          <router-link :to="_brandUrl(brandUrl + '?t=store')" replace>
            <span class="m-menu-ttl">Cửa hàng</span>
          </router-link>
        </li>

        <li
          class="m-menu-sub"
          id="m-menu-sub"
          :class="{
            is_active: $route.query.t === 'style',
          }"
        >
          <router-link :to="_brandUrl(brandUrl + '?t=style')" replace>
            <span class="m-menu-ttl">Style</span>
          </router-link>
        </li>

        <li
          class="m-menu-sub"
          id="m-menu-sub"
          :class="{
            is_active: $route.query.t === 'discussion',
          }"
        >
          <router-link :to="_brandUrl(brandUrl + '?t=discussion')" replace>
            <span class="m-menu-ttl">Đánh giá</span>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="dsi_header-right"></div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const brandUrl = route.params.path + "-b." + route.params.bid;
    return { brandUrl, router };
  },
};
</script>
