import Api from '@/services/Api';

export default {
    login(params) {
        return Api().post('4.0/auth' , params);
    },
    logout(){   
        localStorage.removeItem('user');
        return Api().get('4.0/auth?mode=logout');
    },
    register(params){
        return Api().post('4.0/users', params);
    },
    update(params){
        return Api().put('4.0/auth/' + params.user_id ,params);
    },
    checkUserExistWithPhone(phoneNumber){
        return Api().get('4.0/users?isUserExistWithPhone='+phoneNumber);
    },
    getUserIdByPhone(phoneNumber){
        return Api().get('4.0/users?getUserIdByPhone='+phoneNumber);
    }
}