<template>
  <div>
    <Header />
    <router-view v-slot="{ Component }">
      <keep-alive
        :include="['styles', 'shops', 'brand', 'home', 'DailySuggest', 'AllProducts']"
      >
        <component :is="Component" />
      </keep-alive>
    </router-view>

    <footer-pc v-if="route.meta.footer" />
  </div>
</template>

<script>
import Header from "@/layouts/pc/Header";
import { useRoute } from "vue-router";
export default {
  name: "LayoutPC",
  components: {
    Header,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
};
</script>
