<template>
    <button 
        v-if="!loading"
        :class="['brand_follow-btn famous-followed' , isFollow ? 'active' : '']" 
        type="button" 
        @click="followable"
    >
        <i class="dosiin_follow-icon dsi" :class="isFollow ? 'dsi-following' : 'dsi-act-follow'"></i>
        <span class="follow-btn-text" v-text="isFollow ? 'Đã theo dõi' : 'Theo dõi'"></span>
    </button>

    <button v-else>
        <pulse-loader :loading="loading" color="#000"></pulse-loader>
    </button>
</template>

<script>
    import { ref ,computed, watch , inject  } from 'vue';
    import FollowService from '@/services/followable';
    import { useStore } from 'vuex';
    
    export default {
        emits: ['update:modelValue'],
        props:{
            is_follow :{
                type : Boolean
            },
            followable_type :{
                type : String
            },
            followable_id : {
                type : [Number , String]
            }, 
            modelValue : [Number , String]
        },
        setup(props , {emit}){
            const store = useStore();
            const isFollow = ref(props.is_follow);
            const loggedIn   = computed(() => store.state.account.status.loggedIn);
            const dosiinUser = computed(() => store.state.account.user);
            const loading    = ref(false);
            const openModalLogin = inject('openModalLogin');
            const action = computed({
                get : () => { 
                    return isFollow.value ? 'unfollow' : 'follow'
                }
            });

            watch(() => props.is_follow , (first, second)=> {
                isFollow.value = props.is_follow;
            });
            
            const followable = async () => {

                if(!loggedIn.value){
                    openModalLogin();
                    return;
                }

                let params = {
                    followable_id   :  props.followable_id,
                    followable_type :  props.followable_type,
                    follower_id     :  dosiinUser.value.user_id
                };
                
                loading.value = true;

                if(action.value == 'follow'){
                    follow(params);
                }

                if(action.value == 'unfollow'){
                    unfollow(params);
                }
                
            }   


            const follow = async (params) => {
                const response = await FollowService.follow(params);

                if(response.data){
                    isFollow.value = true;
                    loading.value = false;
                    emit('update:modelValue', response.data.follow_count);
                }
            }

            const unfollow = async (params) => {
                const response = await FollowService.unfollow(params);

                if(response.data){
                    isFollow.value = false;
                    loading.value = false;
                    emit('update:modelValue', response.data.follow_count);
                }
            }

            return {
                isFollow,
                loading,
                //mothods 
                followable
            }
        },
    }
</script>