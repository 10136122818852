export default {
  "style": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nơi chia sẻ và lưu giữ phong cách thời trang"])},
    "top_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài viết nổi bật"])},
    "top_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag thịnh hành"])},
    "suggest_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có thể bạn biết họ"])},
    "top_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài viết"])}
  },
  "account": {
    "order_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin đơn hàng"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài Khoản"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng xuất"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])}
  },
  "products": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả sản phẩm"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các sản phẩm đến từ các thương hiệu Việt Nam"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sản phẩm"])},
    "products_bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sản phẩm bán chạy"])},
    "products_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sản phẩm yêu thích"])},
    "products_trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sản phẩm nổi bật"])}
  },
  "filter": {
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc"])},
    "category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân loại"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá cả"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thương hiệu"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Màu sắc"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích thước"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đặt lại"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áp dụng"])}
  },
  "menus": {
    "shop_menu": {
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xếp hạng"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự kiện"])},
      "price_shock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá Sốc"])},
      "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mới nhất"])},
      "alls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nữ"])},
      "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phụ kiện"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thương hiệu"])},
      "onsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá sốc"])},
      "musinsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musinsa"])}
    }
  },
  "pages": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123"])}
  }
}