<template>
    <div class="dosiin_empty center">
        <img :src="_emptyData" />
        <h3 v-if="title">Không có kết quả hiển thị</h3>
        <p v-if="des"> Chúng tôi đã tìm tất cả các từ khoá và <br> và không có kết quả nào phù hợp với tìm kiếm của bạn</p>
    </div>
</template>

<script>
    import { toRefs } from 'vue';
    export default {
        props:{
            title :{
                type : Boolean,
                default : false
            },
            des :{
                type : Boolean,
                default : false
            }
        },
        setup(props) {
            return {
                ...toRefs(props)
            }
        },
    }
</script>