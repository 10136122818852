<template>
  <button
    v-if="objectType === 'vnfd'"
    type="button"
    :class="
      icon == 'white'
        ? 'dosiin_like-style d-flex align-items-center justify-content-between'
        : 'like dosiin_like-style dosiin_like-icon'
    "
    @click="actionLike()"
  >
    <i class="dsi" :class="liked ? 'dsi-act-fill-love' : 'dsi-love'"></i>
    <span class="dosiin_ml-2" v-if="icon == 'white'">{{ likeCount }}</span>
    <span v-else-if="icon == 'text'">Thích</span>
  </button>

  <button
    v-else
    type="button"
    class="like dosiin_like-style dosiin_like-icon dosiin_mr-2"
    @click="actionLike()"
  >
    <i class="dsi" :class="liked ? 'dsi-act-fill-love' : 'dsi-love'"></i>
  </button>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch, inject } from "vue";
import { useRouter } from "vue-router";

import LikeService from "@/services/like";

export default {
  props: {
    isLiked: {
      type: Boolean,
      default: false,
    },
    likeable_type: {
      type: String,
      require: true,
    },
    likeable_id: {
      type: [Number, String],
      require: true,
    },
    indexPost: {
      type: String,
    },
    type: {
      type: String,
    },
    objectType: {
      type: String,
      default: "vnfd",
    },
    modal_detail: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: {},
    },
  },
  emits: ["likeObject", "unlikeObject"],
  setup(props, { emit }) {
    const store = useStore();
    const liked = ref(props.isLiked);
    const disable_click = ref(true);
    const likeCount = computed(() =>
      store.getters["PostsStore/getLikeCountPost"](props.indexPost)
    );
    const loggedIn = computed(() => store.state.account.status.loggedIn);
    const dosiinUser = computed(() => store.state.account.user);
    const openModalLogin = inject("openModalLogin");
    const router = useRouter();

    const icon = computed(() => props.type);
    const action = computed({
      get: () => {
        return liked.value ? "unlike" : "like";
      },
    });

    function actionLike() {
      if (!loggedIn.value) {
        if (props.modal_detail) {
          router.push(props.object.seo_name);
        }
        openModalLogin();
        return;
      }

      if (!disable_click.value) {
        return;
      }

      disable_click.value = false;

      let params = {
        action_type: action.value,
        likeable_id: props.likeable_id,
        likeable_type: props.likeable_type,
        indexPost: props.indexPost,
        liker_id: dosiinUser.value.user_id,
      };

      // change status like button
      liked.value = !liked.value;

      if (props.objectType === "vnfd") store.dispatch("PostsStore/like", params);

      if (params.action_type == "like") {
        userLike(params);
        emit("likeObject");
      }

      if (params.action_type == "unlike") {
        userUnlike(params);
        emit("unlikeObject");
      }
    }

    async function userLike(params) {
      await LikeService.like(params);
      disable_click.value = true;
    }
    async function userUnlike(params) {
      await LikeService.unlike(params);
      disable_click.value = true;
    }

    watch(
      () => props.isLiked,
      (newLike, prevLike) => {
        liked.value = newLike;
      }
    );

    return {
      liked,
      icon,
      likeCount,
      objectType: props.objectType,
      //methods
      actionLike,
      userLike,
      userUnlike,
    };
  },
};
</script>
