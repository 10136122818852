export default {
    cartProducts: (state, getters, rootState) => {
        return state.items.map(({ id, quantity }) => {
            const product = rootState.products.all.find(product => product.id === id)
            return {
                title: product.title,
                price: product.price,
                quantity
            }
        })
    },
  
    cartTotalPrice: (state, getters) => {
        return getters.cartProducts.reduce((total, product) => {
            return total + product.price * product.quantity
        }, 0)
    },


    cartTotalItems: (state , getters) => {
        let originCart = state.originCart;

        if(originCart.hasOwnProperty('amount')) {
            return state.originCart.amount;
        }

        return 0;
    },

    userData : (state , getters) => {
        const userData = state.originCart.user_data;
        return userData ? userData : {};
    },

    isInit : (state , getters) => {
        return state.init;
    }
}
  