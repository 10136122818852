<template>
    <footer class="footer-offset footer" id="dosiin_main-menu">
        <ul class="dosiin_main-menu footer_container container-fluid">
            <li class="nav-item">
                <router-link class="nav-link footer_item" :to="{name : 'home-home'}" title="Trang chủ">
                    <i class="dsi dsi-s24" :class="['home-home','ranking','events','newest','on-sale'].includes(route.name) ? 'dsi-act-fill-home' : 'dsi-home'"></i>
                    <span class="footer-page_name" :class="['home-home','ranking','events','newest','on-sale'].includes(route.name) ? 'active' : ''">Trang chủ</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link footer_item" :to="{ name : 'shops'}" title="Sản phẩm">
                    <i class="dsi dsi-s24" :class="route.name == 'shops' ? 'dsi-act-fill-store' : 'dsi-store'"></i>
                    <span class="footer-page_name" :class="route.name == 'shops' ? 'active' : ''" >Sản phẩm</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link footer_item" :to="{ name : 'brand'}" title="Thương hiệu">
                    <i class="dsi dsi-brand dsi-s24" :class="route.name == 'brand' ? 'dsi-act-fill-brand' : 'dsi-brand'"></i>
                    <span class="footer-page_name" :class="route.name == 'brand' ? 'active' : ''">Thương hiệu</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link footer_item" :to="{ name : 'notifications-home'}" title="Thông báo">
                    <i class="dsi dsi-alert dsi-s24"></i>
                    <span class="footer-page_name">Thông báo</span>
                </router-link>
            </li>
            <li class="nav-item">
                <a class="nav-link footer_item" href="/user-profile" title="Cá nhân">
                    <i class="dsi dsi-user dsi-s24" alt="Sản phẩm"></i>
                    <span class="footer-page_name">Cá nhân</span>
                </a>
            </li>
        </ul>
    </footer>
</template>

<script>
    import { useRoute } from 'vue-router';

    export default {
        setup() {
            const route = useRoute();

            return {
                route
            }
        },
    }
</script>
