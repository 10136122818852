<template>
    <div class="breadCrumb-product-categories">
        <div class="dosiin-categories-page-detail dosiin-categories-page-detail-unset">
            <div class="title-detai-categories title-detai-categories-btn">

                <a href="/" title="Trang chủ" class="text-home dosiin_mr-2">Trang chủ</a>

                <template v-for="(breadcumb , index ) in breadcumbs" :key="index">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="13.5665" y="2.95703" width="1.45833" height="16.6342" rx="0.729166" transform="rotate(30 13.5665 2.95703)" fill="#B3B3B3"></rect>
                    </svg>
                    <router-link  v-if="breadcumb.route !== '#'" :to=" breadcumb.type_category ? breadcumb.route + `?type_category=` + breadcumb.type_category : breadcumb.route " class="text-home">{{ breadcumb.title }}</router-link>
                    <span  v-else :href="breadcumb.route" class="text-home">{{ breadcumb.title }}</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { toRefs } from 'vue';
    
    export default {
        props:{
            breadcumbs : {
                type : Array,
                require : true,
                default : []
            }
        },
        setup(props) {
            return {
                ...toRefs(props)
            }
        },
    }
</script>