import Api from '@/services/Api';

export default {
  follow(params) {
    return Api().post('/api/followable', params);
  },
  unfollow(params) {
    return Api().put(`/api/followable/${params.follower_id}`, params);
  },
  fetchFollowers(id, params) {
    return Api().get(`/api/followable/${id}`, { params });
  }
}