const url = process.env.VUE_APP_FILE_URL;
const CDN = process.env.VUE_APP_IMG_ENG;
const isSupportWebp = _isSupportFormatWebp();

import { unserialize } from '@/utlis/serialize';
import { defaultAvatar } from '@/utlis/global';

export function _postImage(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.icon && pairsObject.icon.relative_path) {
    return addExtension(CDN + pairsObject.icon.relative_path + '?w=670&h=670&fit=crop');
  }
}

export function _postImageWithFullUrl(imageLink) {
  let imagePath = parse_url(imageLink);
  if(imagePath && imagePath.relative_path){
    return addExtension(CDN + imagePath.relative_path + '?w=670&h=670&fit=crop');
  }
  return '';
}

export function _postThumbnail(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.icon) {
    return addExtension(CDN + pairsObject.icon.relative_path + '?w=670&h=670');
  }
}

export function _eventThumbnail(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.icon) {
    return addExtension(url + pairsObject.icon.relative_path + '?w=320&h=320');
  }
}

export function _productThumbnailSerializie(pairsObjectString) {

  const pairsObject = unserialize(pairsObjectString);

  if (!pairsObject) return;
  if (pairsObject.detailed) {
    return addExtension(url + pairsObject.detailed.relative_path + '?w=320&h=320&fit=fill');
  }
}

export function _productThumbnail(pairsObject, origin = false) {

  if (!pairsObject) return;
  if (pairsObject.detailed && !origin) {
    return addExtension(url + pairsObject.detailed.relative_path + '?w=320&h=320&fit=fill');
  } else {
    return addExtension(url + pairsObject.detailed.relative_path + '?w=1000&h=1000&fit=fill');
  }
}


export function _productThumbnailSticker(pairsObject, stickerObject, origin = false) {

  if (typeof pairsObject === 'string' || pairsObject instanceof String) {
    pairsObject = unserialize(pairsObject);
  }

  var imageUrl = '';
  if (!pairsObject) return;

  if (pairsObject.detailed && !origin) {
    imageUrl = url + pairsObject.detailed.relative_path + '?w=320&h=320&fit=fill';
  }

  if (pairsObject.detailed && origin) {
    imageUrl = url + pairsObject.detailed.relative_path + '?w=670&h=670&fit=fill';
  }

  if (stickerObject && stickerObject.icon) {
    imageUrl += '&mark=' + stickerObject.icon.relative_path + '&markpos=top-left'
  }

  return addExtension(imageUrl);
}

export function _userAvatar(pairsObject) {
  if (!pairsObject) {
    return defaultAvatar[Math.floor(Math.random() * defaultAvatar.length)];
  };

  if (pairsObject.icon) {
    return addExtension(url + pairsObject.icon.relative_path + '?w=320&h=320');
  }
}

export function _userRandomAvatar() {
  return defaultAvatar[Math.floor(Math.random() * defaultAvatar.length)];
}

export function _companyAvatar(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.image) {
    return addExtension(url + pairsObject.image.relative_path + '?w=320&h=320');
  }
}

export function _companyAvatarNewest(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.image) {
    return addExtension(url + pairsObject.image.relative_path + '?w=140&h=140');
  }
}

export function _companyBanner(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.detailed) {
    return addExtension(url + pairsObject.detailed.relative_path + '?w=670&h=670');
  }
}


export function _brandBanner(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.icon) {
    return addExtension(url + pairsObject.icon.relative_path + '?w=1200&h=500&fit=fill');
  }
  if (pairsObject.detailed) {
    return addExtension(url + pairsObject.detailed.relative_path + '?w=1200&h=500&fit=fill');
  }
}

export function _brandBannerCrop(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject && pairsObject.icon) {
    return addExtension(url + pairsObject.icon.relative_path + '?w=1200&h=500&fit=crop');
  }
  if (pairsObject && pairsObject.detailed) {
    return addExtension(url + pairsObject.detailed.relative_path + '?w=1200&h=500&fit=crop');
  }
}

// export function _brandBannerPC(){
//     if(!pairsObject) return;
//     if(pairsObject.detailed){
//         return url +  pairsObject.detailed.relative_path + '?w=1200&h=500&&fit=fill';
//     }
// }

export function _catetagThumbnail(pairsObject) {
  if (!pairsObject) return;
  if (pairsObject.icon) {
    return addExtension(url + pairsObject.icon.relative_path + '?w=314&h=172');
  }
}

// banner 
export function _bannerImage(pairsObject) {
  if (pairsObject && pairsObject.icon) {
    return addExtension(url + pairsObject.icon.relative_path + '?w=1200&h=500');
  }
  if (pairsObject && pairsObject.detailed) {
    return addExtension(url + pairsObject.detailed.relative_path + '?w=1200&h=500');
  }
}

export function _promotionThumb(pairsObject) {
  if (pairsObject && pairsObject.icon) {
    return addExtension(url + pairsObject.icon.relative_path + '?w=100&h=100&crop=fit');
  }
}


export function _commentThumb(image, thumb = true) {
  image = image.replace('/images/', '');
  if (thumb) return addExtension(url + image + '?w=100&h=100&fit=crop');
  else return addExtension(url + image + '?w=670&h=670&fit=fill');
}

function addExtension(imageUrl) {
  if (isSupportWebp) {
    return imageUrl + '&fm=webp';
  }
  return imageUrl;
}

function _isSupportFormatWebp() {
  var elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}

function parse_url($url) {
  var url = $url.split('//');
  if (url[0] === "http:" || url[0] === "https:") {
    var protocol = url[0] + "//";
    var host = url[1].split('/')[0];
    url = protocol + host;
    var urlImage = protocol + host + '/images/';
    var path = $url.split(url)[1];
    var relative_path = $url.split(urlImage)[1];

    return {
      protocol: protocol,
      host: host,
      path: path,
      relative_path
    };
  }
}