<template>
    <form 
        class="dosiin_w-100"
        @submit.prevent="handleLogin"
    >
        <div class="form-group" :class="errorUser ? 'error' : ''">
            <input 
                class="form-control"  
                v-model.trim="auth.user_login" 
                type="text" 
                ref="userLogin"
                autocomplete="tel"
                required
            />
            <label>Email hoặc Số điện thoại</label>
        </div>

        <div class="form-group" :class="errorPassword ? 'error' : ''">
            <input 
                class="form-control js-form-pass" 
                v-model.trim="auth.password"
                type="password"
                ref="password"
                v-password
                required
            >
            <label for="name">Mật khẩu</label>
            <i class="delete">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66667 10.5003C1.66667 5.90553 5.40521 2.16699 10 2.16699C14.5948 2.16699 18.3333 5.90553 18.3333 10.5003C18.3333 15.0951 14.5948 18.8337 10 18.8337C5.40521 18.8337 1.66667 15.0951 1.66667 10.5003ZM10 2.79922C5.75331 2.79922 2.29889 6.25363 2.29889 10.5003C2.29889 14.747 5.75331 18.2014 10 18.2014C14.2467 18.2014 17.7011 14.747 17.7011 10.5003C17.7011 6.25363 14.2467 2.79922 10 2.79922ZM7.84099 8.34131C7.96499 8.21731 8.16321 8.21731 8.28721 8.34131L10 10.0541L11.7128 8.34131C11.8368 8.21731 12.035 8.21731 12.159 8.34131C12.283 8.46531 12.283 8.66353 12.159 8.78753L10.4461 10.5005L12.166 12.2133C12.2899 12.3373 12.2899 12.5354 12.1659 12.6593C12.1038 12.7215 12.0242 12.7523 11.9411 12.7523C11.8579 12.7523 11.7784 12.7215 11.7162 12.6593L10.0034 10.9465L8.29065 12.6593C8.22852 12.7215 8.14895 12.7523 8.06582 12.7523C7.98269 12.7523 7.90313 12.7215 7.84099 12.6593C7.71699 12.5353 7.71699 12.3371 7.84099 12.2131L9.55379 10.5003L7.84099 8.78753C7.71699 8.66353 7.71699 8.46531 7.84099 8.34131Z" fill="#222222"></path>
                </svg>
            </i>
            <span class="show-pass"><span class="text-pink-gradient">Hiện</span></span>
        </div> 

        <div class="group-check">
            <router-link :to="{name : 'reset-password'}" class="forget-pass">Quên mật khẩu?</router-link>
            <router-link :to="{name : 'login-sms'}" class="login-sms">Đăng nhập sms</router-link>
        </div>
        
        <button class="primary-button button btn-login" type="submit">
            <span v-if="!loading" class="primary-link_text">Đăng nhập</span>
            <pulse-loader v-else :loading="loading"></pulse-loader>
        </button>
    </form>
</template>

<script>

    import { _isEmail , _isVietnamesePhoneNumber , _minLength }  from '@/utlis/validation';
    import { ref , inject } from 'vue';
    import { useToast } from "vue-toastification";
    import AuthService from '@/services/auth';
    import { useStore } from 'vuex';
    import { useRouter , useRoute } from 'vue-router';

    export default {
        inject: ['mainRoute'],
        setup() {
            const store = useStore();
            const errorUser     = ref(false);
            const errorPassword = ref(false);
            const toast     = useToast();
            const userLogin = ref(null);
            const password  = ref(null);
            const loading   = ref(false);
            const router    = useRouter();
            const route     = useRoute();
            const mainRoute = inject('mainRoute');

            const auth = ref({
                user_login : '',
                password : ''
            });

            async function handleLogin() {
                let isUserLogin = _isEmail(auth.value.user_login);
                let isPassword  = _minLength(auth.value.password , 6);
                if(!isUserLogin){
                    isUserLogin = _isVietnamesePhoneNumber(auth.value.user_login);
                }
                
                // wrong email and phone number
                if(!isUserLogin){
                    errorUser.value = true;
                    userLogin.value.focus();
                    toast.error('Email hoặc số điện thoại không đúng');
                    return;
                }

                
                if(!isPassword){
                    errorUser.value = false;
                    errorPassword.value = true;
                    toast.error('Mật khẩu phải có ít nhất 6 kí tự');
                    password.value.focus();
                    return;
                }
                
                loading.value = true;
                
                const response = await AuthService.login({
                    user_login :auth.value.user_login , 
                    password :auth.value.password
                });

                if(typeof response == 'undefined'){
                    loading.value = false;
                }else if(response.data.accessToken){
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    store.dispatch('account/login' ,response.data.user);
                    loading.value = false;

                    if(route.query.next){
                        // router.replace(decodeURIComponent(route.query.next));
                        window.location.href = decodeURIComponent(route.query.next);
                    }else if(!['login'].includes(route.name)){
                        window.location.reload();
                    }else{
                        router.push({name : mainRoute});
                    }
                        
                }else{
                    loading.value = false;
                }
            }

            return {
                auth,
                errorUser,
                errorPassword,
                userLogin,
                password,
                handleLogin,
                loading
            }
        },
    }
</script>