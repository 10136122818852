<template>
  <div
    :class="['dropdown_share', $style.top, $style.left]"
    v-click-outside="closeShare"
    v-if="openShare"
    :style="{ transform: `translate(${left}px, ${top}px) translate(-50%, 0px)` }"
  >
    <div :class="['dropdown_share-inner', $style.bgWhite]">
      <div v-if="arrow_top" class="dropdown_share-polygon">
        <img :src="_polygon" alt="polygon" />
      </div>

      <LoadingComponent
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
      />

      <div
        v-else-if="postShare"
        :key="postShare.vnfd_id"
        class="open-modal"
        id="dosiin_social-interact-modal"
      >
        <div
          class="social-interact-modal_container"
          :class="!modal_detail ? 'modal-container_bottom' : ''"
          id="dosiin_social-modal_container"
        >
          <div class="social-interact_btn-group">
            <a
              class="btn-follow btn-social"
              @click="copyLinkToClipboard"
              title="Sao chép liên kết"
            >
              <i class="dsi dsi-link"></i>
              <p>Sao chép liên kết</p>
            </a>

            <a
              class="btn-follow btn-social"
              target="_blank"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                originUrl + '/' + postShare.seo_name
              )}`"
              title="Chia sẻ lên Facebook"
            >
              <i class="dsi dsi-color-fbcircle"></i>
              <p>Chia sẻ lên Facebook</p>
            </a>

            <a
              class="btn-follow btn-social"
              @click="shareMess"
              title="Chia sẻ lên Messenger"
            >
              <i class="dsi dsi-color-messenger"></i>
              <p>Chia sẻ lên Messenger</p>
            </a>

            <a
              class="btn-follow btn-social"
              target="_blank"
              :href="`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                postShare.short_description
              )}&url=${encodeURIComponent(originUrl + '/' + postShare.seo_name)}`"
              title="Chia sẻ lên Twitter"
            >
              <i>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.125 5.4174C17.5279 5.68261 16.8856 5.85957 16.2081 5.9433C16.8969 5.53326 17.4268 4.87927 17.6755 4.10671C17.0309 4.48552 16.3169 4.76386 15.5573 4.9105C14.9506 4.26556 14.0854 3.86548 13.1267 3.86548C11.2852 3.86548 9.7926 5.34906 9.7926 7.17841C9.7926 7.43729 9.82284 7.68938 9.87971 7.93423C7.10932 7.79483 4.65216 6.47419 3.00834 4.47013C2.71948 4.95983 2.55699 5.53326 2.55699 6.13837C2.55699 7.28839 3.1442 8.30082 4.03968 8.89733C3.49309 8.88059 2.97855 8.72807 2.52811 8.48367C2.52811 8.49363 2.52811 8.50856 2.52811 8.52259C2.52811 10.1297 3.6777 11.468 5.20146 11.7726C4.92298 11.8491 4.62869 11.8916 4.32539 11.8916C4.11009 11.8916 3.90021 11.8672 3.69711 11.8305C4.12138 13.1439 5.35221 14.1039 6.81053 14.1324C5.66951 15.0195 4.23241 15.5508 2.66983 15.5508C2.39992 15.5508 2.13588 15.5354 1.875 15.5037C3.35137 16.441 5.10442 16.9905 6.9861 16.9905C13.1177 16.9905 16.4722 11.9414 16.4722 7.5613C16.4722 7.41783 16.4676 7.27481 16.4609 7.1336C17.1153 6.67016 17.6795 6.08541 18.125 5.4174Z"
                    fill="#1692EE"
                  />
                </svg>
              </i>
              <p>Chia sẻ lên Twitter</p>
            </a>

            <a
              class="btn-follow btn-social"
              :data-href="`${originUrl}/${encodeURIComponent(postShare.seo_name)}`"
              data-oaid="579745863508352884"
              data-layout="2"
              data-color="white"
              data-customize="false"
              title="Chia sẻ lên Zalo"
              v-if="layout == 4"
            >
              <i class="dsi dsi-color-zalocircle"></i>
              <p>Chia sẻ lên Zalo</p>
            </a>

            <input
              type="hidden"
              :value="`${originUrl}/${encodeURIComponent(postShare.seo_name)}`"
              ref="postLinkToCopy"
            />
          </div>
        </div>
      </div>

      <div v-if="arrow_bottom" class="dropdown_share-polygon">
        <img :src="_polygon" alt="polygon" style="transform: rotate(180deg)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useToast } from "vue-toastification";
import vClickOutside from "click-outside-vue3";

const toast = useToast();

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const openShare = ref(false);
    const loading = ref(true);
    const postShare = ref({});
    const originUrl = window.location.origin;
    const left = ref(0);
    const top = ref(0);
    const postLinkToCopy = ref(null);
    const modal_detail = ref(false);
    const arrow_top = ref(false);
    const arrow_bottom = ref(false);

    const shareMess = () => {
      let originLink = encodeURIComponent(originUrl + "/" + postShare.value.seo_name);
      if (Tygh.layout == 4) {
        window.open("fb-messenger://share/?link=" + originLink + "&app_id=123456789");
      } else {
        window.open(
          "http://www.facebook.com/dialog/send?app_id=640120160152421&display=popup&link=" +
            originLink +
            "&redirect_uri=" +
            originLink,
          "targetWindow",
          `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=500`
        );
      }
    };

    function updatePost(post, event, modal_post = false) {
      let tag = event.target.tagName;
      let target = event.target;
      if (tag !== "BUTTON") {
        target = target.parentElement;
      }

      var elemRect = target.getBoundingClientRect(),
        bodyRect = document.body.getBoundingClientRect(),
        eleTop = elemRect.top - bodyRect.top,
        eleLeft = elemRect.left;

      if (modal_post) {
        top.value = elemRect.top - 183;
        left.value = elemRect.left;
        modal_detail.value = true;
        arrow_bottom.value = !arrow_bottom.value;
        arrow_top.value = false;
      } else {
        if (elemRect.bottom > 730) {
          top.value = eleTop - 50;
          arrow_bottom.value = !arrow_bottom.value;
          arrow_top.value = false;
        } else {
          top.value = eleTop + 50;
          arrow_bottom.value = false;
          arrow_top.value = !arrow_top.value;
        }

        left.value = eleLeft + elemRect.width / 2;
      }

      openShare.value = true;
      postShare.value = post;
      setTimeout(() => {
        loading.value = false;
      }, 300);
    }

    function copyLinkToClipboard() {
      var linkToShare = postLinkToCopy.value;
      linkToShare.setAttribute("type", "text");
      linkToShare.select();
      document.execCommand("copy");
      linkToShare.setAttribute("type", "hidden");
      toast.success("Đường dẫn đã được sao chép , hãy dán vào đâu bạn muốn");
    }

    function closeShare(event) {
      if (openShare) {
        openShare.value = false;
      }

      loading.value = true;
    }

    return {
      postShare,
      shareMess,
      updatePost,
      copyLinkToClipboard,
      closeShare,
      loading,
      openShare,
      originUrl,
      top,
      left,
      postLinkToCopy,
      modal_detail,
      arrow_top,
      arrow_bottom,
    };
  },
};
</script>

<style module>
.top {
  top: 0;
}
.left {
  left: 0;
}

.bgWhite {
  background-color: #fff;
  border-radius: 10px;
}
</style>
