<template>
  <li
    :class="[
      'dosiin_header-right-item',
      state.showNotification || route.meta.isNotification ? 'bgItem' : '',
    ]"
    @click="toggleNotification"
  >
    <!-- {{ route }}
        {{ route.meta }} -->
    <div class="dosiin_header-right-icon">
      <i
        :class="[
          'dsi dsi-s24',
          state.showNotification || route.meta.isNotification
            ? 'dsi-act-fill-alert'
            : 'dsi-alert',
        ]"
      ></i>
    </div>
    <!-- <div class="dosiin_header-right-nofication"> 
            <span>0</span>
        </div> -->
    <Teleport to="#modals">
      <div
        v-click-outside="CloseShowNotification"
        v-if="state.showNotification"
        class="ds_dropdowAlert noti_active"
      >
        <img class="d_noti-polygon" :src="_polygon" alt="Polygon" />
        <div class="ds_alert">
          <div class="ds_notification-box-1">
            <div class="d_noti-top dosiin_d-flex">
              <p>Thông báo</p>
              <router-link
                :to="{ name: 'notifications-home' }"
                class="dosiin_d-flex"
                titl="xem tất cả"
                v-on:click="CloseShowNotification"
              >
                <span>Xem tất cả</span>
                <div class="dsi dsi-act-arrow-forward"></div>
              </router-link>
            </div>
            <div class="ds_notification-scrolly">
              <div class="ds_notification-scrolly-content">
                <ul class="d_noti-list">
                  <li>
                    <div class="notification-description_wrapper-color-event">
                      <router-link
                        :to="{ name: 'notifications-event' }"
                        class="d_noti-item dosiin_d-flex"
                        title="xem sự kiện"
                        v-on:click="CloseShowNotification"
                      >
                        <div
                          class="notification-description_img notification-item_img-wrapper"
                        >
                          <img
                            class="name-notification"
                            width="36"
                            height="36"
                            :src="_event"
                            alt="event"
                          />
                        </div>
                        <div class="notification-description-main-page_info">
                          <router-link
                            :to="{ name: 'notifications-event' }"
                            class="dosiin_d-block"
                            title="xem sự kiện"
                          >
                            <h5 class="notification-item_title">
                              Sự kiện <span>({{ state.events.length }})</span>
                            </h5>
                          </router-link>
                          <dl
                            class="notification-item_title-sub"
                            v-if="state.events.length > 0"
                          >
                            <dd v-text="state.events[0].name"></dd>
                          </dl>
                        </div>
                        <div class="notification-see-more">
                          <router-link
                            :to="{ name: 'notifications-event' }"
                            class="dosiin_d-block"
                            title="xem sự kiện"
                          >
                            <i class="dsi dsi-s24 dsi-arrow-forward"></i>
                          </router-link>
                        </div>
                      </router-link>
                    </div>
                  </li>
                  <!-- <li>
                                        <div class="notification-description_wrapper-color-style d_noti-item dosiin_d-flex">
                                            <div class="notification-description_img notification-item_img-wrapper">
                                                <router-link :to="{}" >
                                                    <img class="name-notification" width="36" height="36" :src="_promotion" alt="event" />
                                                </router-link>
                                            </div>
                                            <div class="notification-description-main-page_info"><a href="./notificationsPromotion.html" title="Promotion">
                                                    <h5 class="notification-item_title">Style <span>(9)</span></h5>
                                                </a>
                                                <dl class="notification-item_title-sub">
                                                    <dd>Nguyen và 3 người khác đã cập nhật</dd>
                                                </dl>
                                            </div>
                                            <div class="notification-see-more">
                                                <a href="./notificationsPromotion.html" title="Promotion">
                                                    <i class="dsi dsi-s24 dsi-arrow-forward"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li> -->
                  <li>
                    <div class="notification-description_wrapper-color-promotion">
                      <router-link
                        :to="{ name: 'notifications-promo' }"
                        class="d_noti-item dosiin_d-flex"
                        title="xem khuyến mãi"
                        v-on:click="CloseShowNotification"
                      >
                        <div
                          class="notification-description_img notification-item_img-wrapper"
                        >
                          <img
                            class="name-notification"
                            width="36"
                            height="36"
                            :src="_brand"
                            alt="khuyến mãi"
                          />
                        </div>
                        <div class="notification-description-main-page_info">
                          <router-link :to="{ name: 'notifications-promo' }">
                            <h5 class="notification-item_title">
                              Khuyến mãi <span>({{ state.promotions.length }})</span>
                            </h5>
                          </router-link>
                          <dl
                            class="notification-item_title-sub"
                            v-if="state.promotions.length > 0"
                          >
                            <dd>
                              {{
                                state.promotions[Object.keys(state.promotions)[0]].name
                              }}
                            </dd>
                          </dl>
                        </div>
                        <div class="notification-see-more">
                          <router-link :to="{ name: 'notifications-promo' }">
                            <i class="dsi dsi-s24 dsi-arrow-forward"></i>
                          </router-link>
                        </div>
                      </router-link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </li>
</template>

<script>
import { reactive } from "vue";
import EventService from "@/services/event";
import PromotionService from "@/services/promotion";
import vClickOutside from "click-outside-vue3";
import { useRouter, useRoute } from "vue-router";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      showNotification: false,
      events: [],
      promotions: [],
    });

    getEvents();
    getPromotions();
    function toggleNotification() {
      return (state.showNotification = !state.showNotification);
    }

    async function getEvents() {
      const response = await EventService.fetchOriginEvent();
      if (response.data) {
        state.events = response.data.events;
      }
    }

    async function getPromotions() {
      const response = await PromotionService.fetchPromotions();
      if (response.data) {
        state.promotions = response.data.promotions;
      }
    }
    function CloseShowNotification(event) {
      state.showNotification = false;
    }
    return {
      state,
      toggleNotification,
      CloseShowNotification,
      route,
    };
  },
};
</script>
