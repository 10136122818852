export default {
    userInfo: (state, getters, rootState) => {
        return state.user;
    },

    getUserToken : (state, getters, rootState) => {
        if(state.user){
            return state.user.token
        }
        return '';
    }
};