// import Vue from 'vue';
import { createWebHistory, createRouter } from "vue-router";
// import { useMeta, createMetaManager as createVueMetaManager, defaultConfig ,plugin } from 'vue-meta'
import MainLayout from "@/layouts/Layout";
import { authRoute } from "@/router/authGuard";

const routes = [{
  path: "/",
  component: MainLayout,
  children: [{
      name: "home",
      path: "/",
      component: () =>
        import ("@/pages/home/HomeLayout"),
      meta: {
        usePathKey: true,
        footer: true,
        isShopping: true,
      },
      children: [{
          name: "home-home",
          path: "",
          component: () =>
            import ("@/pages/home/Home"),
          meta: {
            title: process.env.VUE_APP_PAGE_TITLE,
          },
        },
        {
          name: "events",
          path: "/dosiin-events",
          component: () =>
            import ("@/pages/home/Events"),
          meta: {
            title: process.env.VUE_APP_PAGE_TITLE,
          },
        },
        {
          name: "ranking",
          path: "/dosiin-ranking",
          component: () =>
            import ("@/pages/home/Ranking"),
          meta: {
            title: process.env.VUE_APP_PAGE_TITLE,
          },
        },
        {
          name: "newest",
          path: "/dosiin-newest",
          component: () =>
            import ("@/pages/home/Newest"),
          meta: {
            title: process.env.VUE_APP_PAGE_TITLE,
          },
        },
        {
          name: "on-sale",
          path: "/dosiin-on-sale",
          component: () =>
            import ("@/pages/home/OnSale"),
          meta: {
            title: 'Dosi-in | Các thương hiệu đang khuyến mãi',
          },
        },
        {
          name: "musinsa",
          path: "/musinsa",
          component: () =>
            import ("@/pages/home/Musinsa"),
          meta: {
            title: process.env.VUE_APP_PAGE_TITLE,
          },
        },
      ],
    },
    {
      name: "style",
      path: "/style",
      component: () =>
        import ("@/pages/Vnfd"),
      meta: {
        isStyle: true,
      },
    },
    {
      name: "search",
      path: "/search",
      component: () =>
        import ("@/pages/search/Search"),
      meta: {
        footer: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "shops",
      path: "/shops",
      component: () =>
        import ("@/pages/Shops"),
      meta: {
        usePathKey: true,
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "brands-newarrival",
      path: "/brands-newarrival",
      component: () =>
        import ("@/pages/BrandsNewArrival"),
      meta: {
        usePathKey: true,
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "brand",
      path: "/brand",
      component: () =>
        import ("@/pages/Brands"),
      meta: {
        usePathKey: true,
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "brands-bestselling",
      path: "/brands-bestselling",
      component: () =>
        import ("@/pages/BrandsBestSelling"),
      meta: {
        usePathKey: true,
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "brands-highlight",
      path: "/brands-highlight",
      component: () =>
        import ("@/pages/BrandsHighLight"),
      meta: {
        usePathKey: true,
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "categories",
      path: "/:path-c.:cid(\\d+)",
      component: () =>
        import ("@/pages/Categories"),
      props: (route) => ({ categoryParam: route.params }),
      meta: {
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "product",
      path: "/:path-p.:id(\\d+)",
      component: () =>
        import ("@/pages/Product"),
      props: (route) => ({ productParam: route.params }),
      meta: {
        footer: true,
        isShopping: true
      },
    },
    {
      name: "brand_shop",
      path: "/:path-b.:bid(\\d+)",
      component: () =>
        import ("@/pages/Brand"),
      props: (route) => ({ brandParam: route.params }),
      meta: {
        footer: true,
        isShopping: true,
        isBrandPage: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "page",
      path: "/:path-l.:lid(\\d+)",
      component: () =>
        import ("@/pages/Page"),
      props: (route) => ({ pageParam: route.params }),
      meta: {
        usePathKey: true,
        footer: true,
      },
    },
    {
      name: "brand_shop_cat",
      path: "/:path-b.:bid(\\d+)/:path-c.:cid(\\d+)",
      component: () =>
        import ("@/pages/Brand"),
      props: (route) => ({ brandParam: route.params }),
      meta: {
        footer: true,
        isShopping: true,
        isBrandPage: true,
      },
    },
    {
      name: "products",
      path: "/products",
      component: () =>
        import ("@/pages/Products"),
      meta: {
        footer: true,
        isShopping: true,
      },
    },
    {
      name: "products_suggestion",
      path: "/daily-suggest",
      component: () =>
        import ("@/pages/ProductsDailySuggest"),
      meta: {
        footer: true,
        isShopping: true,
        title: 'Dosi-in | Gợi ý hôm nay cho bạn',
      },
    },
    {
      name: "products-shock",
      path: "/products-shock",
      component: () =>
        import ("@/pages/PromotionsShock"),
      meta: {
        footer: true,
        isShopping: true,
        title: 'Dosi-in | Thương hiệu đang giảm giá',
      },
    },
    {
      name: "promotion-detail",
      path: "/promotion-detail",
      component: () =>
        import ("@/pages/PromotionShockDetail.vue"),
      meta: {
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "trending",
      path: "/trending",
      component: () =>
        import ("@/pages/ProductsTrending"),
      meta: {
        footer: true,
        isShopping: true,
        title: 'Dosi-in | Sản phẩm nổi bật',
      },
    },
    {
      name: "best-selling",
      path: "/best-selling",
      component: () =>
        import ("@/pages/ProductsBestSelling"),
      meta: {
        footer: true,
        isShopping: true,
        title: 'Dosi-in | Sản phẩm bán chạy',
      },
    },
    {
      name: "favourite",
      path: "/favourite",
      component: () =>
        import ("@/pages/ProductsFavorite"),
      meta: {
        footer: true,
        isShopping: true,
        title: 'Dosi-in | Sản phẩm yêu thích',
      },
    },
    {
      name: "new-arrival",
      path: "/new-arrival",
      component: () =>
        import ("@/pages/ProductsNewArrival"),
      meta: {
        footer: true,
        isShopping: true,
        title: 'Dosi-in | Sản phẩm mới',
      },
    },
    {
      name: "tags",
      path: "/style-tag",
      component: () =>
        import ("@/pages/Tags"),
      meta: {
        isStyle: true,
      },
    },
    {
      name: "style-cat",
      path: "/style-cat",
      component: () =>
        import ("@/pages/CatTopic"),
      meta: {
        isStyle: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "topic",
      path: "/style-topic",
      component: () =>
        import ("@/pages/Topic"),
      meta: {
        isStyle: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "detail",
      //path: "/style-post",
      path: "/:path-v.:vid(\\d+)",
      props: (route) => ({ vParams: route.params }),
      component: () =>
        import ("@/pages/Post"),
      meta: {
        isStyle: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "profile",
      path: "/profile-user",
      component: () =>
        import ("@/pages/UserProfile"),
      meta: {
        title: "Trang cá nhân | Dosiin",
      },
    },
    {
      name: "notifications-home",
      path: "/notifications",
      component: () =>
        import ("@/pages/notifications/Notifications"),
      meta: {
        footer: true,
        isNotification: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "notifications-event",
      path: "/notifications-event",
      component: () =>
        import ("@/pages/notifications/NotificationsEvents"),
      meta: {
        footer: true,
        isNotification: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "notifications-promo",
      path: "/notifications-promo",
      component: () =>
        import ("@/pages/notifications/NotificationsPromo"),
      meta: {
        footer: true,
        isNotification: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "exclusive",
      path: "/exclusive",
      component: () =>
        import ("@/pages/Exclusive"),
      meta: {
        footer: true,
        isShopping: true,
        title: process.env.VUE_APP_PAGE_TITLE,
      },
    },
    {
      name: "login",
      path: "/login",
      component: () =>
        import ("@/pages/auth/Login"),
      meta: {
        footer: true,
        title: "Đăng nhập | Dosiin",
      },
    },
    {
      name: "login-sms",
      path: "/login-sms",
      component: () =>
        import ("@/pages/auth/LoginSMS"),
      meta: {
        footer: true,
        title: "Đăng nhập SMS | Dosiin",
      },
    },
    {
      name: "register",
      path: "/register",
      component: () =>
        import ("@/pages/auth/Register"),
      meta: {
        footer: true,
        title: "Đăng kí tài khoản | Dosiin",
      },
    },
    {
      name: "reset-password",
      path: "/reset-password",
      component: () =>
        import ("@/pages/auth/ResetPassword"),
      meta: {
        footer: true,
        title: "Khôi phục mật khẩu | Dosiin",
      },
    },
    {
      name: 404,
      path: "/not-found",
      component: () =>
        import ("@/pages/404"),

    },
  ],
}, ];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 500);
      });
    }
  },
});

router.beforeEach((to, from, next) => {
  // change background color
  var colorDetect = to.meta.backgroundColor ? to.meta.backgroundColor : "";
  document.body.style.backgroundColor = colorDetect;

  // auth page
  const isAuthPages = authRoute.includes(to.name);
  const user = localStorage.getItem("user");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (isAuthPages && user) {
    next({ name: "home" });
  }

  // keep next query params in auth pages
  if (!to.query.next && from.query.next && isAuthPages) {
    let nextUrl = decodeURIComponent(from.query.next);
    next({ name: to.name, query: { next: encodeURIComponent(nextUrl) } });
  }

  next();
});

router.afterEach((to, from) => {
  if (to.meta.title) document.title = to.meta.title;
});

export default router;