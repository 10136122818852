export default {
    // called before bound element's attributes
    // or event listeners are applied
    created(){},
    mounted(ele, binding, vnode, prevVnode) {
        // see below for details on arguments
        var menuItem = ele.closest('.dosiin_dropdown-menu-items');
        var dropdownMenu = menuItem.getElementsByClassName('dosiin_dropdown-submenu')[0];
      
        ele.addEventListener('click', (event) => {
            event.preventDefault();
            dropdownMenu.classList.toggle('d-none');
        });
    },
    // called right before the element is inserted into the DOM.
    beforeMount() {},
    // called when the bound element's parent component
    // and all its children are mounted.
    // mounted() {},
    // called before the parent component is updated
    beforeUpdate() {},
    // called after the parent component and
    // all of its children have updated
    updated() {},
    // called before the parent component is unmounted
    beforeUnmount() {},
    // called when the parent component is unmounted
    unmounted() {}
};
