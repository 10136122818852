export default {
    // called before bound element's attributes
    // or event listeners are applied
    created(){},
    mounted(ele, binding, vnode, prevVnode) {
        // see below for details on arguments
        var parent = ele.parentElement;
        var closeButton = parent.getElementsByTagName('I')[0];
        var showButton = parent.getElementsByTagName('SPAN')[0];
        ele.addEventListener('keydown', (event) => {
            if(event.target.value.length > 1){
                closeButton.style.display = 'inline';
                showButton.style.display = 'flex';
            }else{
                closeButton.style.display = '';
                showButton.style.display = '';
            }
        });

        closeButton.addEventListener('click' , (event) =>  {
            ele.value = '';
            closeButton.style.display = '';
            showButton.style.display = '';
        });
        
        showButton.addEventListener('click' , (event) =>  {
            if(ele.type == 'password'){
                ele.type = 'text';
            }else{
                ele.type = 'password';
            }
        });
    },
    // called right before the element is inserted into the DOM.
    beforeMount() {},
    // called when the bound element's parent component
    // and all its children are mounted.
    // mounted() {},
    // called before the parent component is updated
    beforeUpdate() {},
    // called after the parent component and
    // all of its children have updated
    updated() {},
    // called before the parent component is unmounted
    beforeUnmount() {},
    // called when the parent component is unmounted
    unmounted() {}
};
